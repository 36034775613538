/**
 * This enum should list all valid paths of the app.
 * Typed router components and hooks, such as `TypedLink`,
 * validate nav targets and path params against these paths.
 */
export enum Path {
  Root = '',
  Auth = '/auth',
  GuestSignup = '/guest-signup',
  ActionHandlerAction = '/action-handler/action',
  NotFound = '/404',
  SystemAdmin = '/sysadmin',

  // Workspace pages
  WorkspaceCreate = '/workspace/create',
  // Using `workspaceJoinId` instead of `workspaceId` so components relying on
  // `workspaceId` will not pick it up accidentally.
  WorkspaceJoinViaInvitation = '/join/:workspaceJoinId/:invitationId?',
  Workspace = '/workspace/:workspaceId',

  // Talent Acquisition pages
  AcquisitionRoot = '/workspace/:workspaceId/talent-acquisition',
  AcquisitionOpenings = '/workspace/:workspaceId/talent-acquisition/openings',
  AcquisitionFlowDefinition = '/workspace/:workspaceId/opening/:flowDefinitionId',
  AcquisitionFlowBasicSettings = '/workspace/:workspaceId/opening/:flowDefinitionId/edit',

  // Talent Development pages
  DevelopmentRoot = '/workspace/:workspaceId/development-hub',
  DevelopmentFlows = '/workspace/:workspaceId/development-hub/flows',
  DevelopmentFlowDefinition = '/workspace/:workspaceId/flow/:flowDefinitionId',
  DevelopmentFlowDefinitionHistory = '/workspace/:workspaceId/flow/:flowDefinitionId/history',
  DevelopmentFlowBasicSettings = '/workspace/:workspaceId/flow/:flowDefinitionId/edit',

  // Flow instance pages
  FlowInstance = '/workspace/:workspaceId/flow/:flowDefinitionId/instance/:flowInstanceId',
  Session = '/workspace/:workspaceId/flow/:flowDefinitionId/instance/:flowInstanceId/session/:sessionId',

  // Shared flow instance pages
  SharedEmbeddedFlowInstance = '/share/:shareId',
  SharedEmbeddedSession = '/share/:shareId/session/:sessionId',
  AcquisitionInvitationEmbeddedFlowInstance = '/candidate-invite/:shareId',
  CandidateEmbeddedSession = '/candidate-invite/:shareId/session/:sessionId',

  // Flow editor pages
  FlowEditorHome = '/flow-editor',
  FlowVersionEditor = '/flow-editor/:flowProjectId',
  FlowVersionSessionBundles = '/flow-editor/:flowProjectId/session-bundles',
  FlowVersionModules = '/flow-editor/:flowProjectId/modules',
  FlowVersionSettings = '/flow-editor/:flowProjectId/settings',

  // Session editor pages
  SessionEditor = '/flow-editor/:flowProjectId/session-editor/:sessionBundleId/:sessionVersionId',
  SessionEditorPreview = '/flow-editor/:flowProjectId/session-editor/:sessionBundleId/:sessionVersionId/preview',
}

export function buildPath(
  path: Path,
  params?: Record<string, string | number>,
) {
  let url = path.startsWith('/') ? path : `/${path}`;
  if (params && typeof params === 'object') {
    Object.keys(params).forEach((key) => {
      url = url.replaceAll(`:${key}`, (params[key] as string) ?? '');
    });
  }

  return url;
}

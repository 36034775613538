import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getFocusManagerLockExpirationTime } from '@features/canvas/services/FocusManager/utils';
import { useFocusManagerContext } from '@features/canvas/services/FocusManager/hooks';
import { UUID } from 'src/@types/common';
import { DefaultFocusManagerLockEditNoteExpiration } from 'src/utils/lock/lock.constants';
import { useUserId } from 'src/utils/userContent/hooks';
import { useTypedSelector } from '../useTypedSelector';

type LockStatus = 'idle' | 'locked' | 'request-unlock';

interface IEditedItemState {
  id: UUID;
  status: LockStatus;
}

const initialState: IEditedItemState = {
  id: '',
  status: 'idle',
};

const slice = createSlice({
  name: 'editedItem',
  initialState,
  reducers: {
    startEdit(state, action: PayloadAction<UUID>) {
      state.id = action.payload;
      state.status = 'locked';
    },
    stopEdit(state, action: PayloadAction<UUID | undefined>) {
      const targetItemId = action.payload;
      const isTargetingSpecificItem = targetItemId !== undefined;
      const shouldUnlockTargetedItem =
        isTargetingSpecificItem && state.id === targetItemId;
      if (shouldUnlockTargetedItem || !targetItemId) {
        return initialState;
      }
    },
    requestStopEdit(state) {
      if (state.id) {
        state.status = 'request-unlock';
      }
    },
  },
});

const { actions, reducer } = slice;

/**
 * Locks a canvas item, so it can be edited by the user.
 * @returns Memoized callback
 */
function useEditCanvasItem() {
  const dispatch = useDispatch();
  const focusManagerCtx = useFocusManagerContext();
  const userId = useUserId();

  return useCallback(
    function editCanvasItem(itemId: UUID) {
      if (itemId) {
        focusManagerCtx.lock(
          userId,
          itemId,
          getFocusManagerLockExpirationTime(
            DefaultFocusManagerLockEditNoteExpiration,
          ),
        );
      }

      dispatch(actions.startEdit(itemId));
    },
    [dispatch, focusManagerCtx, userId],
  );
}

/**
 * Unlocks an edited canvas item.
 * @returns Memoized callback
 */
function useStopEditCanvasItem() {
  const dispatch = useDispatch();
  const focusManagerCtx = useFocusManagerContext();

  return useCallback(
    function stopEditCanvasItem(itemId?: UUID) {
      if (itemId) {
        focusManagerCtx.unlock(itemId);
      }

      dispatch(actions.stopEdit(itemId));
    },
    [dispatch, focusManagerCtx],
  );
}

/**
 * Notifies the edited canvas item that it should
 * save its state and unlock itself when ready.
 * @returns Memoized callback
 */
function useRequestStopEditCanvasItem() {
  const dispatch = useDispatch();
  return useCallback(
    function requestStopEditCanvasItem() {
      dispatch(actions.requestStopEdit());
    },
    [dispatch],
  );
}

/**
 * Checks if a canvas item is being edited, if the ID is provided.
 * If no ID is provided, then checks if any item is being edited.
 * @param id - Optional canvas item ID
 */
function useIsEditingCanvasItem(id?: UUID) {
  return useTypedSelector((state) => {
    const editedId = state.activeCanvas.editedItem.id;
    const isSpecificItemQueried = !!id;
    return isSpecificItemQueried
      ? editedId === id
      : editedId !== initialState.id;
  });
}

export {
  reducer,
  useEditCanvasItem,
  useIsEditingCanvasItem,
  useRequestStopEditCanvasItem,
  useStopEditCanvasItem,
};

/**
 * Contains a list of Firebase auth error codes that might require unique logic.
 */
export const AuthErrorCodeMap = {
  /**
   * The user is trying to sign up with an email that already has an associated account.
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_3
   */
  EmailTaken: 'auth/email-already-in-use',
  /**
   * The user is trying to reset a password that is too weak.
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#confirmpasswordreset
   */
  WeakPassword: 'auth/weak-password',
  /**
   * The Google auth flow was cancelled because the user has opened multiple popups.
   * The UI probably shouldn't allow this.
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_15
   */
  PopupCancelled: 'auth/cancelled-popup-request',
  /**
   * The Google auth flow failed because the user has closed the popup before authenticating.
   * This isn't a UI error.
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_15
   */
  PopupClosedByUser: 'auth/popup-closed-by-user',
} as const;

export const PASSWORD_MIN_LENGTH = 8;

import { IsBoolean, IsOptional } from 'class-validator';
import { UnknownPartial } from 'src/services/database/types';
import { _CanvasItemConfigBaseDto } from './_canvasItemConfigBase.dto';

/**
 * Existing canvas notes added in the "editor" (e.g. canvas notes)
 */
export class CanvasDefaultContentDto<
  TContent = UnknownPartial,
> extends _CanvasItemConfigBaseDto<TContent> {
  /**
   * TODO Not implemented yet.
   * Whether the user can update the item's _content_ (e.g. the cell of a table) in the database.
   * Examples: the content of a table cell; the assigned scores saved in a scoring grid.
   * @default true
   */
  @IsOptional()
  @IsBoolean()
  isContentEditable?: boolean;

  /**
   * TODO Not implemented yet.
   * Whether the user can update the item's coordinates or parent nodes in the database.
   * @default true
   */
  @IsOptional()
  @IsBoolean()
  isMovable?: boolean;

  constructor(dto: CanvasDefaultContentDto<TContent>) {
    super(dto);
    this.isContentEditable = dto.isContentEditable ?? true;
    this.isMovable = dto.isMovable ?? true;
  }
}

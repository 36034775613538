/**
 * @returns `true` if the app is running on a touch device.
 * @see https://thewebdev.info/2021/02/27/how-to-detect-a-touch-screen-device-using-javascript/
 */
function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    window.matchMedia('(pointer: coarse)').matches
  );
}

/**
 * @returns `true` if the navigator user agent implies a tablet or mobile browser
 */
function isUserAgentTabletOrMobile() {
  return (
    /(ipad|iphone|tablet|playbook|silk)|(android(?!.*mobi))/i.test(
      navigator.userAgent,
    ) &&
    !/(windows(?!.*touch)|tablet|folio|(r?pocket))/i.test(navigator.userAgent)
  );
}

export { isTouchDevice, isUserAgentTabletOrMobile };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UUID } from 'src/@types/common';
import { useTypedSelector } from '../useTypedSelector';

const initialState = '' as UUID;

const slice = createSlice({
  name: 'idMarkedForDelete',
  initialState,
  reducers: {
    markForDelete(_state, action: PayloadAction<UUID>) {
      return action.payload;
    },
    reset() {
      return '';
    },
  },
});

export const { reducer } = slice;
const { actions } = slice;

/**
 * Allows storing a canvas item's ID when the user shows intention of deleting it.
 * This information can be used for confirming the operation.
 * @returns A memoized callback to store a canvas item's ID as the deletion target
 */
export function useMarkForDeletion() {
  const dispatch = useDispatch();
  return useCallback(
    function markForDeletion(canvasItemId: UUID) {
      dispatch(actions.markForDelete(canvasItemId));
    },
    [dispatch],
  );
}

/**
 * Allows cancelling the deletion intent of a canvas item.
 * @returns A memoized callback to dismiss the deletion target's ID
 */
export function useCancelMarkForDeletion() {
  const dispatch = useDispatch();
  return useCallback(
    function markForDeletion() {
      dispatch(actions.reset());
    },
    [dispatch],
  );
}

/**
 * @returns ID of the canvas item that the user intends to delete
 */
export function useGetItemIdMarkedForDeletion() {
  return (
    useTypedSelector((state) => state.activeCanvas.idMarkedForDelete) ??
    undefined
  );
}

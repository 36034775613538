import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  FirestoreError,
  onSnapshot,
  SnapshotListenOptions,
} from '@firebase/firestore';
import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { trace } from '../telemetry';
import { IFirestoreDocumentSubscription, VoidFn } from './types';

// TODO try merging with sibling hooks

/**
 * This is a generic hook that can be used for subscribing to a **single** Firestore document.
 * This pattern is preferred over async/await-ing `getDoc()` calls to ensure good performance.
 *
 * The result of this hook should be passed to a `useSyncExternalStore` hook (see example below).
 *
 * Apart from the Firestore data, the whole document snapshot is returned (including its meta data),
 * so it's easier to manage the document's lifecycle (e.g. the state of read/write ops).
 *
 * **Not useful for:**
 * - writing data to Firestore
 * - reading multiple documents with queries (due to typing difficulties).
 * Use `useFirestoreAllDocsSubscription()` instead.
 *
 * **Error handling:** If Firestore returns with an error, the listener won't receive any more events,
 * so in this case the parent will probably need to remount this hook.
 * See [Handle listen errors](https://firebase.google.com/docs/firestore/query-data/listen#handle_listen_errors).
 *
 * @example
 * ```ts
 * const TodoDataConverter: FirestoreDataConverter<TodoType, TodoType> = { ... }
 * function useGetTodoDoc(id: UUID) {
 *   // Memoization prevents infinite re-renders
 *   const memoizedQuery = useMemo(() =>
 *     doc(getFirestore(), `todoCollectionName/${id}`)
 *   , [id])
 *   const todoStore = useFirestoreDocSubscription<TodoType>(TodoDataConverter, memoizedQuery)
 *   return useSyncExternalStore(todoStore.subscribe, todoStore.getSnapshot)
 * }
 * ```
 *
 * @template TFromDb - The data shape coming from the database.
 * @template TToDb - The data shape going to the database. This needs to be set if the data shape contains conversions. Defaults to `FromDb`.
 * @param dataConverter - A Firestore data converter that should
 * - handle conversion to/from Firestore-specific data types
 * - assert the type safety of incoming data.
 * (This could be applied directly to `memoizedDocReference`, but this way it won't be forgotten.)
 * @param memoizedDocReference - Firestore doc reference targeting a single document.
 * **Be sure it's memoized to avoid infinite re-renders!**
 * @param listenOptions - Optional config that allows listening to meta data changes of a document. See {@link SnapshotListenOptions}
 * @returns A subscription that should be passed to a `useSyncExternalStore` hook
 * @throws {Error} If the collection path is invalid.
 * @see https://react.dev/reference/react/useSyncExternalStore
 * @see https://firebase.google.com/docs/firestore/query-data/listen
 * @see https://firebase.google.com/docs/reference/js/v8/firebase.firestore.FirestoreDataConverter
 */
export function useFirestoreDocSubscription<
  TFromDb extends DocumentData,
  TToDb extends DocumentData = TFromDb,
>(
  dataConverter: FirestoreDataConverter<TFromDb, TToDb>,
  memoizedDocReference: DocumentReference | undefined,
  listenOptions?: SnapshotListenOptions,
): IFirestoreDocumentSubscription<TFromDb> {
  const data = useRef<DocumentSnapshot<TFromDb> | undefined>();
  const getSnapshot = useCallback(() => data.current, []);
  const memoListenOptions = useRef(listenOptions); // TODO maybe update with useEffect?

  const [error, setError] = useState<FirestoreError | undefined>();
  useEffect(
    function handleError() {
      if (error) {
        trace({
          category: 'firebase',
          level: 'error',
          message: error.message,
          name: error.name,
          data: data.current,
        });
      }
    },
    [error],
  );

  const subscribe = useCallback(
    (updateListener: VoidFn) => {
      if (!memoizedDocReference) {
        return () => {};
      }
      const unsubscribe = onSnapshot(
        memoizedDocReference.withConverter(dataConverter),
        memoListenOptions.current ?? { includeMetadataChanges: false },
        {
          next(result) {
            const hasDataChanged = !isEqual(data.current, result);
            const hasMetaChanged = data.current?.metadata.isEqual(
              result.metadata,
            );
            if (hasDataChanged) {
              data.current = result;
            }
            if (hasDataChanged || hasMetaChanged) {
              updateListener();
            }
          },
          error(error) {
            setError(error);
          },
        },
      );
      return () => {
        data.current = undefined;
        unsubscribe();
      };
    },
    [dataConverter, memoizedDocReference],
  );

  return useMemo(
    () => ({
      error,
      getSnapshot,
      subscribe,
    }),
    [error, getSnapshot, subscribe],
  );
}

import { getCdnUrl } from 'src/env';
import { userAvatarColor } from 'src/styles/colors/userAvatar.css';
import { AuthErrorCodeMap } from './constants';

/**
 * @param errorMessage - Message that may contain an error code listed in {@link AuthErrorCodeMap}.
 * @returns `true` if the error returned by Firebase should be ignored by the UI.
 */
export function isIgnoredAuthError(errorMessage: string) {
  const ignoredErrors = [
    AuthErrorCodeMap.PopupCancelled,
    AuthErrorCodeMap.PopupClosedByUser,
  ];
  return ignoredErrors.some((code) => errorMessage.includes(code));
}

/**
 * Returns an integer based on the user's Firebase UID.
 *
 * @param fireBaseUserId
 *
 * @returns number
 */
function getUserId(fireBaseUserId: string): number {
  const numbers = fireBaseUserId.match(/\d+/g);
  return numbers ? numbers.reduce((a, b) => Number(a) + Number(b), 0) : 0;
}

/**
 * Returns an element from an array based on the user's Firebase UID.
 * The UID is converted to a number, and the modulo of the number is used to get the index of the element.
 *
 * @param fireBaseUserId
 * @param dataArray
 *
 * @returns string
 *
 */
function getElementFromArray(
  fireBaseUserId: string,
  dataArray: string[],
): string {
  const UID = getUserId(fireBaseUserId);
  const adjectiveIndex = UID % dataArray.length;
  const element = dataArray[adjectiveIndex];
  return element === undefined ? '' : element;
}

/**
 * Returns a guest user's first name based on the user's Firebase UID.
 *
 * @param fireBaseUserId
 *
 * @returns string
 */
export function getGuestUserAdjectiveName(fireBaseUserId: string): string {
  return getElementFromArray(fireBaseUserId, politicallyCorrectAdjectives);
}

/**
 * Returns a guest user's last name based on the user's Firebase UID.
 *
 * @param fireBaseUserId
 *
 * @returns string
 */
export function getGuestUserColorName(fireBaseUserId: string): string {
  return getElementFromArray(fireBaseUserId, colors);
}

/**
 * Returns a guest user's avatar based on the user's Firebase UID, prefixed with the CDN base path.
 *
 * @param fireBaseUserId
 *
 * @returns string
 */
export function getGuestUserAvatar(fireBaseUserId: string): string {
  const PATH = 'images/guest-avatars/';
  return getCdnUrl(PATH + getElementFromArray(fireBaseUserId, avatars));
}

/**
 * Return a guest user's animal name based on the user's avatar.
 *
 * @param fireBaseUserId
 *
 * @returns string
 */
export function getGuestUserAnimalName(fireBaseUserId: string): string {
  const animalName = getElementFromArray(fireBaseUserId, avatars).split('.')[0];
  return (
    (animalName ?? '').charAt(0).toUpperCase() + (animalName ?? '').slice(1)
  );
}

/**
 * Returns a guest user's full name based on the user's Firebase UID.
 *
 * @param fireBaseUserId
 *
 * @returns string
 *
 * */
export function generateGuestUserDisplayName(fireBaseUserId: string): string {
  return `${getGuestUserAdjectiveName(fireBaseUserId)} ${getGuestUserColorName(
    fireBaseUserId,
  )} ${getGuestUserAnimalName(fireBaseUserId)}`;
}

const politicallyCorrectAdjectives: string[] = [
  'Adaptable',
  'Altruistic',
  'Attentive',
  'Authentic',
  'Balanced',
  'Benevolent',
  'Caring',
  'Charismatic',
  'Compassionate',
  'Cooperative',
  'Creative',
  'Curious',
  'Dynamic',
  'Empathetic',
  'Enthusiastic',
  'Fearless',
  'Focused',
  'Generous',
  'Genuine',
  'Grateful',
  'Humble',
  'Innovative',
  'Inspiring',
  'Intuitive',
  'Joyful',
  'Kind-hearted',
  'Mindful',
  'Motivated',
  'Nurturing',
  'Open-minded',
  'Optimistic',
  'Patient',
  'Peaceful',
  'Perceptive',
  'Polite',
  'Proactive',
  'Progressive',
  'Rational',
  'Reliable',
  'Resourceful',
  'Responsible',
  'Selfless',
  'Skillful',
  'Spirited',
  'Supportive',
  'Thoughtful',
  'Tranquil',
  'Unbiased',
  'Uplifting',
  'Virtuous',
];

const colors: (keyof typeof userAvatarColor)[] = [
  'Lavander',
  'LavanderBlush',
  'LemonChiffon',
  'Moccasin',
  'MistyRose',
  'White',
  'AntiqueWhite',
  'Cornsilk',
  'AliceBlue',
  'BabyBlue',
  'LavanderGray',
  'LightPink',
  'LightSteelBlue',
  'LightCyan',
  'Honeydew',
  'Khaki',
  'PeachPuff',
  'RosyBrown',
];

const avatars: string[] = [
  'cat.svg',
  'cow.svg',
  'frog.svg',
  'hamster.svg',
  'hedgehog.svg',
  'koala.svg',
  'panda.svg',
];

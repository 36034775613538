import { PropsWithChildren, useEffect, useState } from 'react';
import { Flex } from 'src/app/components/shared/Flex';
import { Spinner } from 'src/app/components/shared/Spinner';

interface IPageLoaderProps {
  /**
   * The page loader will only be displayed if the content takes longer to load
   * than the specified time in milliseconds. This is useful for preventing
   * flickering when the content loads quickly. Defaults to 100ms.
   */
  showAfter?: number;
}

/**
 * The page loader component will display a loading spinner while the page
 * content is being loaded. This component can be updated later to include dummy
 * content to make the loading experience more pleasant.
 */
export function PageLoadingSpinner({
  showAfter = 100,
  children,
}: PropsWithChildren<IPageLoaderProps>) {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShowing(true);
    }, showAfter);

    return () => clearTimeout(timeout);
  }, [showAfter]);

  return isShowing ? (
    <Flex
      direction="column"
      align="center"
      justify="center"
      style={{ height: '100%' }}
      gap="8px"
    >
      <Spinner type="circleBall" size="36px" />
      {children}
    </Flex>
  ) : null;
}

import { ValidationOptions, validateSync } from 'class-validator';
import { WorkspaceMemberDto } from 'src/services/database/Workspaces/dto/workspaceMember.dto';
import { createCustomValidator } from 'src/services/database/validators/utils';

export function isWorkspaceMember(value: unknown) {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const member = value as WorkspaceMemberDto;
  const dto = new WorkspaceMemberDto({
    id: member.id,
    displayName: member.displayName,
    role: member.role,
  });

  return validateSync(dto).length === 0;
}

export function isWorkspaceMemberRecords(value: unknown) {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  return Object.values(value).every((element) => isWorkspaceMember(element));
}

export function IsWorkspaceMemberRecords(
  validationOptions?: ValidationOptions,
) {
  return createCustomValidator(
    'IsWorkspaceMemberRecords',
    isWorkspaceMemberRecords,
    () => 'Each member must be a valid WorkspaceMember object',
    validationOptions,
  );
}

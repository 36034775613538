import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteError } from 'react-router-dom';
import { trace } from 'src/services/telemetry';
import { typography } from 'src/styles/typography.css';
import { Emoji } from '../components/shared/Emoji';
import { Flex } from '../components/shared/Flex';

interface Error500Props {
  message?: string;
}

export default function Error500({
  message = 'An error occurred.',
}: Error500Props) {
  const error = useRouteError();
  useEffect(() => {
    trace(error as Error);
  }, [error]);
  return (
    <>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <Flex
        direction="column"
        style={{
          width: '100vw',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <h1 className={typography.h2} style={{ marginBottom: '1em' }}>
          Something went terribly wr<Emoji title="o">😢</Emoji>ng.
        </h1>
        <p>{message}</p>
      </Flex>
    </>
  );
}

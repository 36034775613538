import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistOnboardingMiddleware } from '@features/onboarding';
import { staticReducers } from './reducers';

export const store = configureStore({
  reducer: combineReducers(staticReducers),
  middleware: (getMiddlewares) =>
    // eslint-disable-next-line unicorn/prefer-spread
    getMiddlewares().concat(persistOnboardingMiddleware),
  devTools: {
    trace: process.env.NODE_ENV !== 'production',
  },
});

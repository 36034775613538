import { ErrorBoundary } from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import Modal from 'react-modal';
import { Provider as ReduxProvider } from 'react-redux';
import {
  hasFirebaseEmulator,
  isDevEnv,
  isProdEnv,
  isStagingEnv,
  VITE_APP_CHECK_DEBUG_TOKEN,
  VITE_CAPTCHA_SITE_KEY,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_WEB_API_KEY,
  VITE_MEASUREMENT_ID,
} from 'src/env';
import { Router } from 'src/app/router/Router';
import Error500 from './app/pages/Error500';
import { initFirebase } from './services/firebase/service';
import { initSentry } from './services/telemetry';
import { store } from './state';
import './styles/fontRaleway.css';
import './styles/global.css';
import './styles/responsive.css';
import './styles/typography.css';

const shouldTraceErrors = isProdEnv() || isStagingEnv();
if (shouldTraceErrors) {
  initSentry();
}

initFirebase(
  {
    apiKey:
      VITE_FIREBASE_WEB_API_KEY ?? 'AIzaSyA7eY9bKKaQ3u34OnyWPTmRaCRyx2rTGbs',
    authDomain:
      VITE_FIREBASE_AUTH_DOMAIN ?? 'spf-fb-app-dev-zjdn.firebaseapp.com',
    projectId: VITE_FIREBASE_PROJECT_ID ?? 'spf-fb-app-dev-zjdn',
    storageBucket:
      VITE_FIREBASE_STORAGE_BUCKET ?? 'spf-fb-app-dev-zjdn.appspot.com',
    messagingSenderId: VITE_FIREBASE_MESSAGING_SENDER_ID ?? '10664995305',
    appId: VITE_FIREBASE_APP_ID ?? '1:10664995305:web:aae11a2558b06492e90378',
    measurementId: VITE_MEASUREMENT_ID ?? 'G-SNELLDM9BF',
  },
  isDevEnv() && hasFirebaseEmulator()
    ? {
        auth: 'http://127.0.0.1:9099',
        firebaseStorage: {
          host: '127.0.0.1',
          port: 9199,
        },
        firestore: {
          host: '127.0.0.1',
          port: 8080,
        },
      }
    : {},
  {
    appCheckDebugToken: VITE_APP_CHECK_DEBUG_TOKEN,
    captchaKey: VITE_CAPTCHA_SITE_KEY,
  },
);

// console.info(`Firebase app ${getFirestore().app.name} has been initialized.`);

const ROOT = '#root';
// http://reactcommunity.org/react-modal/accessibility/#app-element
Modal.setAppElement(ROOT);

const container = document.querySelector(ROOT);
if (container === null) {
  throw new Error('Could not find root element');
}
const root = createRoot(container);

root.render(
  <StrictMode>
    <HelmetProvider>
      <ErrorBoundary fallback={<Error500 />}>
        <ReduxProvider store={store}>
          <Router />
        </ReduxProvider>
      </ErrorBoundary>
    </HelmetProvider>
  </StrictMode>,
);

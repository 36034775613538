import { doc, getDoc, getFirestore } from '@firebase/firestore';
import { CustomError, defekt, isError } from 'defekt';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IDynamicFlowAdapter } from 'src/@types/flow';
import { useTypedNavigate } from 'src/app/router';
import { Path } from 'src/app/router/routes';
import { getPathForFlowDefinitions } from 'src/services/database/paths';
import { trace } from 'src/services/telemetry';
import {
  useFlowDefinitionId,
  useFlowDefinitionPath,
} from 'src/utils/resource.hooks';
import { FlowContext, IFlowContext } from './context';
import loadAdapter from './loadAdapter';

class UnknownFlowError extends defekt({
  code: 'UnknownFlowPath',
  defaultMessage: 'The flow path does not match any known flow subfolders.',
}) {}

/**
 * Provides the flow adapter to nested components in a React context.
 * When receiving a `flowId`, the context provider:
 * - dynamically imports the flow adapter file,
 * - loads existing data from the local database, or populates it if empty,
 */
export function FlowContextProvider({ children }: { children: ReactNode }) {
  const navigate = useTypedNavigate();
  const flowDefinitionId = useFlowDefinitionId();
  const flowPath = useFlowDefinitionPath();
  const [flowAdapter, setFlowAdapter] = useState<IDynamicFlowAdapter | null>(
    null,
  );
  const [flowError, setFlowError] = useState<Error | CustomError | null>(null);

  //#region  =========== Data reset ===========
  useEffect(
    function onDefinitionIdChange() {
      trace({
        category: 'flow',
        level: 'info',
        message: 'Flow ID changed',
        data: { flowDefinitionId },
      });

      setFlowAdapter(null);
    },
    [flowDefinitionId],
  );
  //#endregion  ======== Data reset ===========

  //#region  =========== Flow adapter ===========
  useEffect(
    function maybeLoadAdapter() {
      setFlowError(null);

      if (flowPath) {
        trace({
          category: 'flow',
          level: 'info',
          message: 'Loading flow adapter...',
          data: { flowPath },
        });

        loadAdapter(flowPath)
          .then((result) => {
            const adapter = result.unwrapOrThrow();
            setFlowAdapter(adapter);
          })
          .catch((error) => {
            setFlowError(error as Error | CustomError | null);
            if (isError(error)) {
              trace(error);
            }
          });
      } else {
        trace({
          category: 'flow',
          level: 'debug',
          message: 'No flow path set in flow context',
        });
      }
    },
    [flowPath, setFlowError],
  );
  //#endregion  ======== Flow adapter ===========

  //#region  =========== Error handling ===========
  const handleFallback = useCallback(async () => {
    trace({
      category: 'flow',
      level: 'debug',
      message: 'Flow adapter load failed',
      data: { flowDefinitionId, flowError },
    });

    try {
      const flowDoc = await getDoc(
        doc(getFirestore(), getPathForFlowDefinitions(flowDefinitionId)),
      );
      const wasFlowMadeInEditor = flowDoc.exists();

      if (wasFlowMadeInEditor) {
        trace({
          category: 'flow',
          level: 'debug',
          message: 'Flow definition was made in flow editor',
          data: { flowDefinitionId },
        });
      } else {
        throw new UnknownFlowError();
      }
    } catch (error) {
      navigate(Path.NotFound);
    }
  }, [flowDefinitionId, flowError, navigate]);

  useEffect(
    function redirectOnError() {
      if (flowError) {
        handleFallback();
      }
    },
    [flowError, handleFallback, navigate],
  );
  //#endregion  ======== Error handling ===========

  const contextApi = useMemo<IFlowContext>(
    () => ({
      flowAdapter,
      flowPath,
      flowDefinitionId,
      isFlowLoaded: !!flowDefinitionId && !!flowAdapter,
      setFlowAdapter,
    }),
    [flowAdapter, flowDefinitionId, flowPath],
  );
  return (
    <FlowContext.Provider value={contextApi}>{children}</FlowContext.Provider>
  );
}

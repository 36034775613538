import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'src/state/useTypedSelector';
import { setUser } from 'src/state/user';
import { UserPartialPayload } from 'src/state/user/types';
import { setTelemetryUserId } from 'src/utils/userContent/telemetry';

// TODO move inside user state slice

export function useUser() {
  return useTypedSelector((state) => state.user.user);
}

export function useUserId() {
  return useUser()?.uid;
}

export function useSetUser() {
  const dispatch = useDispatch();
  const userId = useUserId();

  return useCallback(
    (payload: UserPartialPayload) => {
      if (payload.uid && userId !== payload?.uid) {
        setTelemetryUserId(payload?.uid);
      }

      dispatch(setUser(payload));
    },
    [dispatch, userId],
  );
}

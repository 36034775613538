export enum OpenAiResponseSchemaType {
  Object = 'object',
  Array = 'array',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
}

export type OpenAiResponseSchemaObjectElement = {
  readonly type: OpenAiResponseSchemaType.Object;
  readonly properties: Record<string, OpenAiResponseSchema>;
  readonly required: string[];
  readonly additionalProperties: boolean;
};

export type OpenAiResponseSchemaArrayElement = {
  readonly type: OpenAiResponseSchemaType.Array;
  readonly items: OpenAiResponseSchema;
};

export type OpenAiResponseSchemaPrimitiveElement = {
  readonly type:
    | OpenAiResponseSchemaType.String
    | OpenAiResponseSchemaType.Number
    | OpenAiResponseSchemaType.Boolean;
};

export type OpenAiResponseSchema =
  | OpenAiResponseSchemaObjectElement
  | OpenAiResponseSchemaArrayElement
  | OpenAiResponseSchemaPrimitiveElement;

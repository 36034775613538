import clsx from 'clsx';
import { CSSProperties } from 'react';
import { emojiBase } from './Emoji.css';

interface IEmojiProps {
  children: string;
  className?: string;
  style?: CSSProperties;
  title: string;
}

/**
 * Renders a single emoji character with proper accessibility.
 * The emoji is rendered inline, so it inherits the font size,
 * and has a line height of 1.
 *
 * A descriptive title must be provided!
 * See https://emojipedia.org/ for official names.
 *
 * If automatic parsing is a must, check out https://github.com/dreamyguy/react-emojis,
 * but be warned it's 33KB minified and zipped.
 */
export function Emoji({ children, className, style, title }: IEmojiProps) {
  return (
    <span
      style={style}
      role="img"
      aria-label={title}
      className={clsx(emojiBase, className)}
    >
      {children}
    </span>
  );
}

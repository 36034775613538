import {
  IsArray,
  IsEnum,
  IsObject,
  IsOptional,
  IsString,
  MinLength,
  ValidateNested,
} from 'class-validator';
import { UUID } from 'src/@types/common';
import {
  WorkspaceMemberDto,
  WorkspaceMemberRole,
} from 'src/services/database/Workspaces/dto/workspaceMember.dto';
import { IsWorkspaceMemberRecords } from 'src/services/database/Workspaces/validators';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';
import {
  IsFirestoreTimestamp,
  IsFirestoreUUID,
} from 'src/services/database/validators/validators';
import { WorkspaceInvitationSettingsDto } from 'src/services/database/Workspaces/dto/workspaceInvitationSettings.dto';

export enum WorkspaceTerminology {
  LearningAndDevelopment = 'rnd',
  HumanResources = 'hr',
}

/**
 * Workspace DTO is used to keep a validated copy of the workspace data coming
 * from the database.
 */
export class WorkspaceDto {
  @IsFirestoreUUID()
  readonly id: UUID;

  @IsFirestoreTimestamp({ allowNumber: true })
  /** The timestamp when the workspace was created. */
  readonly createdAt: number;

  @MinLength(2)
  /** The unique identifier of the workspace. */
  readonly slug: string;

  @MinLength(1)
  /** The name of the workspace. */
  readonly name: string;

  @IsEnum(WorkspaceTerminology)
  @IsOptional() // TODO [KS] Remove this once we migrated all workspaces
  /** The terminology used by the workspace, like Learning and Development or Human Resources. */
  /** @deprecated */
  readonly terminology?: WorkspaceTerminology;

  @IsString()
  @IsOptional()
  /** The URL of the workspace logo. */
  readonly logo?: string;

  @IsWorkspaceMemberRecords()
  /** The members of the workspace. */
  readonly members: Record<string, WorkspaceMemberDto>;

  @IsArray()
  @MinLength(1, { each: true })
  /** The tags used to categorize the workspace, currently used for filtering flow definitions. */
  readonly tags: FlowDefinitionTag[];

  @MinLength(1)
  /** The ID of the user that created the workspace. */
  readonly createdBy: string;

  @IsObject()
  @ValidateNested()
  readonly invitationSettings: WorkspaceInvitationSettingsDto;

  constructor(dto: WorkspaceDto) {
    this.id = dto.id;
    this.createdAt = dto.createdAt;
    this.slug = dto.slug;
    this.name = dto.name;
    this.logo = dto.logo;
    this.members = dto.members;
    this.tags = dto.tags ?? [
      FlowDefinitionTag.Acquisition,
      FlowDefinitionTag.Development,
    ]; // TODO [KS] Delete this null check when all workspaces have tags
    this.createdBy = dto.createdBy;
    this.invitationSettings = new WorkspaceInvitationSettingsDto(
      dto.invitationSettings ?? {}, // TODO [KS] Delete this null check when all workspaces have invitation settings
    );

    // TODO [KS] Remove this once we migrated members or deleted workspaces
    //  This is a fix for creator members that didn't have IDs, and are using
    //  the old role system.
    if (this.members) {
      for (const [key, value] of Object.entries(this.members)) {
        if (value && typeof value === 'object') {
          if ((value.role as unknown) === 'creator') {
            this.members[key] = {
              ...(this.members[key] as WorkspaceMemberDto),
              role: WorkspaceMemberRole.Admin,
            };
          }

          if (!('id' in value)) {
            this.members[key] = {
              ...(this.members[key] as WorkspaceMemberDto),
              id: key,
            };
          }
        }
      }
    }
  }
}

import { PartialWithFieldValue, Timestamp } from '@firebase/firestore';
import { UUID } from 'src/@types/common';
import {
  isEnum,
  isFirestoreTimestamp,
  isNumber,
  isObject,
  isUUID,
  isValidType,
  validateArrayEvery,
  validateIfExists,
} from 'src/utils/typeAssertions/common';
import { AnyTimestamp } from '../types';
import { createConverter } from '../utils';

/**
 * Used within {@link Enrollment}.
 */
export enum EnrollmentStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export type EnrollmentSessionProgress = {
  /** Session definition ID */
  sessionId: UUID;
  /** Highest nudge index the user has interacted with. */
  nudgeHighestSeen: number;
  /** Index of the last nudge the user has interacted with. */
  nudgeIndex: number;
};

/**
 * Represents a user's progress through a specific flow instance.
 *
 * Enrollments can be used for various purposes:
 * - engagement analytics
 * - progress reports
 * - certification of completion.
 *
 * @template TimestampType - Defaults to `number|null` for Unix timestamps,
 * in case a field's value is `serverTimestamp()`'s placeholder value.
 * Allows the Firestore data converter to alter the type of date-time fields.
 */
export type Enrollment<TimestampType = number | null> = {
  /** UNIX timestamp of when the enrollment was created. */
  createdAt: TimestampType;
  /** ID of the user who initiated the bound flow instance. */
  creatorId: UUID;
  /** ID of flow definition whose content the user is consuming. */
  flowDefinitionId: UUID;
  /** ID of the flow instance where the user is interacting with the flow content. */
  flowInstanceId: UUID;
  /** Status indicator of the enrollment creator's progress within the referred flow instance. */
  status: EnrollmentStatus;
  /**
   * ID of workspace where the user has initiated the enrollment document.
   *
   * Although enrollment documents aren't contained within workspace documents,
   * storing the workspace ID allows for easier database lookup.
   */
  workspaceId: UUID;
  /** Progress of the user within each session of the flow instance. */
  sessionProgress?: EnrollmentSessionProgress[];
};

export type EnrollmentFromDb = Omit<Enrollment, 'createdAt'> & {
  /** Firestore {@link Timestamp} of when the enrollment was created. */
  createdAt: Timestamp;
};
export type EnrollmentToDb = Enrollment<AnyTimestamp>;

export function isEnrollmentSessionProgress(
  value: unknown,
): value is EnrollmentSessionProgress {
  return isValidType<EnrollmentSessionProgress>(value, [
    ['sessionId', isUUID],
    ['nudgeHighestSeen', isNumber],
    ['nudgeIndex', isNumber],
  ]).isValid;
}

export function assertIsEnrollment(
  value: unknown,
  options?: Parameters<typeof isValidType<Enrollment>>[2],
): asserts value is Enrollment {
  const result = isValidType<Enrollment>(
    value,
    [
      ['createdAt', (val) => isFirestoreTimestamp(val) || isNumber(val)],
      ['creatorId', isUUID],
      ['flowDefinitionId', isUUID],
      ['flowInstanceId', isUUID],
      ['status', isEnum(EnrollmentStatus)],
      ['workspaceId', isUUID],
      [
        'sessionProgress',
        validateIfExists(validateArrayEvery(isEnrollmentSessionProgress)),
      ],
    ],
    options,
  );
  if (!result.isValid) {
    throw new Error(`Value isn't an Enrollment type. See error message above.`);
  }
}

export const EnrollmentConverter = createConverter<
  Enrollment,
  EnrollmentFromDb
>(
  function assertIsEnrollmentFromDb(data) {
    assertIsEnrollment(data, { skippedFields: ['createdAt'] });
    const result = isValidType<EnrollmentFromDb>(data, [
      ['createdAt', isFirestoreTimestamp],
    ]);
    if (!result.isValid) {
      throw new Error('`createdAt` prop should be a Timestamp.');
    }
  },

  function assertIsEnrollmentToDb(data, options) {
    const isPartialData =
      isObject(options) && ('merge' in options || 'mergeFields' in options);
    assertIsEnrollment(data, { isPartialAllowed: isPartialData });
  },

  function convertFromDb(data) {
    return {
      ...data,
      createdAt: data.createdAt?.toMillis(),
    };
  },

  function convertToDb(data) {
    const convertedEnrollment: PartialWithFieldValue<EnrollmentToDb> =
      Object.assign({}, data as unknown as Partial<EnrollmentToDb>);
    if (
      convertedEnrollment.createdAt &&
      typeof convertedEnrollment.createdAt === 'number'
    ) {
      convertedEnrollment.createdAt = Timestamp.fromMillis(
        convertedEnrollment.createdAt,
      );
    }
    return convertedEnrollment;
  },
);

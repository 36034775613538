const {
  MODE,
  VITE_CDN_BASEURL,
  VITE_FIREBASE_EMU,
  VITE_AMPLITUDE_ID,
  VITE_CAPTCHA_SITE_KEY,
  VITE_EMAIL_SUPPORT,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_AUTH_DOMAIN,
  BASE_URL,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_WEB_API_KEY,
  VITE_MEASUREMENT_ID,
  VITE_PROD_TLD,
  VITE_SENTRY_DSN,
  VITE_STAGING_TLD,
  VITE_SYSADMIN_EMAIL_DOMAIN,
  VITE_APP_CHECK_DEBUG_TOKEN,
} = import.meta.env;

// console.info('MODE:' + MODE);
// console.info('NODE ENV: ' + process.env.NODE_ENV);
// console.info('EMU: ' + VITE_FIREBASE_EMU);

const VITE_CANVAS_DRAG_BROADCAST_INTERVAL = Number.parseInt(
  import.meta.env.VITE_CANVAS_DRAG_BROADCAST_INTERVAL,
  10,
);

export * from './utils';
export {
  BASE_URL,
  /**
   * @see https://vitejs.dev/guide/env-and-mode.html#modes
   */
  MODE,
  /**
   * Stored in CI env variable.
   * @see https://bitbucket.org/superflow1/webapp/admin/pipelines/repository-variables
   */
  VITE_AMPLITUDE_ID,
  VITE_APP_CHECK_DEBUG_TOKEN,
  VITE_CANVAS_DRAG_BROADCAST_INTERVAL,
  VITE_CAPTCHA_SITE_KEY,
  VITE_CDN_BASEURL,
  VITE_EMAIL_SUPPORT,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_EMU,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_WEB_API_KEY,
  VITE_MEASUREMENT_ID,
  VITE_PROD_TLD,
  VITE_SENTRY_DSN,
  VITE_STAGING_TLD,
  VITE_SYSADMIN_EMAIL_DOMAIN,
};

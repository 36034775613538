import { Transaction } from '@firebase/firestore';
import { useMemo } from 'react';
import { v4 } from 'uuid';
import { UUID } from 'src/@types/common';
import { CanvasContentCreateDto } from '../CanvasContent/dto/canvasContentCreate.dto';
import {
  getCanvasContentDocRef,
  prepareCanvasContentDto,
} from '../CanvasContent/utils.new';
import { getCanvasWidgetDocRef } from '../CanvasWidget/utils';
import { useFlowDefinitionDoc } from './FlowDefinitions';
import { CanvasDefaultContentDto } from './dto/CanvasSessions/canvasDefaultContent.dto';
import { CanvasWidgetConfigDto } from './dto/CanvasSessions/canvasWidgetConfig.dto';

const SYSTEM = 'system';

/**
 * Subscribes to a single session document, so changes are synced live.
 * @param flowDefinitionId - The hook won't subscribe to Firestore as long as this value is undefined (e.g. during a 1st render).
 * @param sessionDefinitionId - The hook won't subscribe to Firestore as long as this value is undefined (e.g. during a 1st render).
 */
export function useSessionDefinition(
  flowDefinitionId: UUID,
  sessionDefinitionId: UUID,
) {
  const flow = useFlowDefinitionDoc(flowDefinitionId);
  return useMemo(() => {
    const session = flow.result
      ?.data()
      ?.sessions.find((s) => s.id === sessionDefinitionId);

    if (session) {
      return session;
    }
  }, [flow.result, sessionDefinitionId]);
}

// TODO maybe introduce prepareDTO() util for flow editor
/**
 * Creates a single canvas widget document based on a configuration object.
 * This function is intended to be used inside Firestore write transactions for atomic updates.
 * @param tx - Firestore {@link Transaction}
 * @param widgetConfig - Config object used as a template to generate the canvas widget document.
 * @param flowInstanceId - ID of the parent flow instance.
 * @param sessionId - ID of the parent session instance.
 * @returns Chainable {@link Transaction}
 */
export function txCreateCanvasWidget(
  tx: Transaction,
  widgetConfig: CanvasWidgetConfigDto,
  flowInstanceId: UUID,
  sessionId: UUID,
) {
  const contentId = v4();
  const ref = getCanvasWidgetDocRef(contentId);
  return tx.set(ref, {
    aiCustomPrompt: widgetConfig.aiCustomPrompt,
    aiIsCustomPromptEnabled: widgetConfig.aiIsCustomPromptEnabled,
    aiIsIgnoredOnEvaluate: widgetConfig.aiIsIgnoredOnEvaluate,
    content: widgetConfig.content,
    contentType: widgetConfig.contentType,
    type: widgetConfig.contentType,
    createdAt: Date.now(),
    createdBy: SYSTEM,
    editedAt: Date.now(),
    editedBy: [],
    flowInstanceId,
    id: contentId,
    // Scalar z-index can be used to order item stack with limited info,
    // because the canvas assigns z-index values based on the movement timestamp.
    lastMovedAt: Date.now() + (widgetConfig.z ?? 0),
    parentNodes: widgetConfig.parentNodes,
    semanticId: widgetConfig.semanticId,
    sessionId,
    x: widgetConfig.x,
    y: widgetConfig.y,
  });
}

/**
 * Creates a single canvas content document based on a pre-defined template with a randomized ID.
 * This function is intended to be used inside Firestore write transactions for atomic updates.
 * @param tx - Firestore {@link Transaction}
 * @param canvasItem - Template used to generate the default canvas content document.
 * @param flowInstanceId - ID of the parent flow instance.
 * @param sessionId - ID of the parent session instance.
 * @returns Chainable {@link Transaction}
 */
export function txCreateCanvasContentForDefaultItem(
  tx: Transaction,
  canvasItem: CanvasDefaultContentDto,
  flowInstanceId: UUID,
  sessionId: UUID,
) {
  const payload = prepareCanvasContentDto(
    new CanvasContentCreateDto({
      content: canvasItem.content,
      contentType: canvasItem.contentType,
      type: canvasItem.contentType,
      createdBy: SYSTEM,
      flowInstanceId,
      sessionId,
      draggableType: canvasItem.draggableType,
      isContentEditable: canvasItem.isContentEditable,
      isMovable: canvasItem.isMovable,
      parentNodes: canvasItem.parentNodes,
      semanticId: canvasItem.semanticId,
      x: canvasItem.x,
      y: canvasItem.y,
    }),
  );
  const ref = getCanvasContentDocRef(payload.id);
  return tx.set(ref, payload);
}

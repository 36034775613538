import {
  QueryConstraint,
  collection,
  doc,
  documentId,
  getFirestore,
  orderBy,
  query,
  where,
} from '@firebase/firestore';
import { useMemo, useRef, useSyncExternalStore } from 'react';
import { CanvasWidgetType } from '@features/canvas/components/canvasComponent.types';
import { UUID } from 'src/@types/common';
import { useFlowInstanceId, useSessionId } from 'src/utils/resource.hooks';
import { getPathForCanvasWidget } from '../paths';
import {
  CollectionQueryArgs,
  FirestoreFilterArg,
  UnknownPartial,
} from '../types';
import { useFirestoreQuerySubscription } from '../useFirestoreQuerySubscription';
import { useMemoizedQueryArgs } from '../utils';
import { getCanvasWidgetConverter } from './converter';
import { CanvasWidgetDto } from './dto/canvasWidget.dto';

/**
 * @param docId - The canvas widget ID
 * @returns Firestore reference to be used as a pointer in e.g. `getDoc()`
 */
export function getCanvasWidgetDocRef<TContent extends UnknownPartial>(
  docId: UUID,
) {
  const converter = getCanvasWidgetConverter<TContent>();
  return doc(getFirestore(), getPathForCanvasWidget(docId)).withConverter(
    converter,
  );
}

/**
 * @template TContent - Specifies the unique data shape based on the canvas widget's type
 * (e.g. drop panels store different data than scoring grids).
 * @param queryArg - If not specified, every item will be returned from the collection.
 * _for the current session in the current flow instance_.
 * Otherwise filtering can be performed by ID and/or object keys, and sorting can also be applied.
 * @param collectionPath - Firestore path of the created document. Used for testing and Storybook development.
 * @returns Firestore collection reference.
 */
export function useCanvasWidgetCol<
  TContent extends UnknownPartial = UnknownPartial,
>(
  queryArg?: CollectionQueryArgs<Partial<CanvasWidgetDto<Partial<TContent>>>>,
  collectionPath?: string,
) {
  const flowInstanceId = useFlowInstanceId();
  const sessionId = useSessionId();
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);

  const filterQuery = useMemo(() => {
    const constraints: QueryConstraint[] = [];
    const baseFilterKey: keyof CanvasWidgetDto = 'contentType';
    const baseFilter = where(
      baseFilterKey,
      'in',
      Object.values(CanvasWidgetType),
    );

    if (filterByIds && filterByIds.length > 0) {
      // Firebase throws when filtering for 'in' with an empty array
      constraints.push(where(documentId(), 'in', filterByIds));
    }
    if (filterBy) {
      filterBy
        .filter(
          function noUndefinedFilterCriteria(
            condition,
          ): condition is FirestoreFilterArg<
            Partial<CanvasWidgetDto<Partial<TContent>>>
          > {
            return condition?.value !== undefined;
          },
        )
        .forEach((condition) => {
          constraints.push(where(condition.key, condition.op, condition.value));
        });
    }
    if (sort) {
      constraints.push(orderBy(sort.key, sort.order));
    }

    // Maybe push default filters if no custom value is set.
    // Setting both default and custom value would result in an AND condition.
    const instanceKey: keyof CanvasWidgetDto = 'flowInstanceId';
    const sessionKey: keyof CanvasWidgetDto = 'sessionId';
    if (!filterBy?.find((filter) => filter?.key === sessionKey)) {
      constraints.push(where(sessionKey, '==', sessionId));
    }
    if (!filterBy?.find((filter) => filter?.key === instanceKey)) {
      constraints.push(where(instanceKey, '==', flowInstanceId));
    }

    return query(
      collection(getFirestore(), collectionPath ?? getPathForCanvasWidget()),
      baseFilter,
      ...constraints,
    );
  }, [collectionPath, filterBy, filterByIds, flowInstanceId, sessionId, sort]);

  const converter = useRef(getCanvasWidgetConverter<TContent>());
  const store = useFirestoreQuerySubscription(converter.current, filterQuery);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(() => ({ result, error: store.error }), [result, store.error]);
}

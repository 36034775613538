import { IsOptional, IsString } from 'class-validator';

export class NudgeTextDto {
  /**
   * If set, rendered separately from the nudge text.
   */
  @IsOptional()
  @IsString()
  emoji?: string;

  /**
   * If set, rendered as a rich text block.
   */
  @IsOptional()
  @IsString()
  text?: string;

  /**
   * If set, rendered as the title formatted as rich text.
   */
  @IsOptional()
  @IsString()
  title?: string;

  constructor(dto: NudgeTextDto) {
    this.emoji = dto.emoji;
    this.text = dto.text;
    this.title = dto.title;
  }
}

import { Analytics, getAnalytics, logEvent } from '@firebase/analytics';
import { AnalyticsPlugin } from 'analytics';

export default function firebasePlugin(): AnalyticsPlugin {
  let analytics: Analytics;
  return {
    name: 'firebase',
    config: {
      // this plugin is disabled by default, because Firebase must be initialized first
      enabled: false,
    },
    initialize: () => {
      // after initializing Firebase we enable this plugin and this function will be called
      analytics = getAnalytics();
    },
    page: () => {
      logEvent(analytics, 'page_view');
    },
    // the any type is coming from the @firebase/analytics library
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    track: (eventName: string, payload?: { [key: string]: any }) => {
      logEvent(analytics, eventName, payload);
    },
  };
}

import { addBreadcrumb, Breadcrumb, captureException } from '@sentry/react';
import { isError } from '@sentry/utils';

type TraceCategory = 'auth' | 'firebase' | 'flow' | 'session' | 'video';
interface CategorizedBreadcrumb extends Breadcrumb {
  category?: TraceCategory;
}
type Trace =
  | (Error & Pick<Breadcrumb, 'data'>)
  | string
  | CategorizedBreadcrumb;

/**
 * Adds a breadcrumb to Sentry for a richer debug context.
 * @param debugInfo - Can be a native `Error`, a breadcrumb, or a simple string message.
 * If it's an error-level breadcrumb, it automatically triggers an error report (i.e. `captureException()` is called).
 * @see https://docs.sentry.io/product/issues/issue-details/breadcrumbs/
 */
export function trace(debugInfo: Trace) {
  if (process.env.NODE_ENV === 'development') {
    traceDev(debugInfo);
  }

  if (debugInfo instanceof Error) {
    if (debugInfo.data) {
      addBreadcrumb({ level: 'error', data: debugInfo.data });
    }
    captureException(debugInfo);
  } else if (typeof debugInfo === 'object' && debugInfo.level === 'error') {
    if (debugInfo.data) {
      addBreadcrumb({ level: 'error', data: debugInfo.data });
    }
    captureException(debugInfo.message);
  } else if (typeof debugInfo === 'string') {
    addBreadcrumb({ message: debugInfo });
  } else {
    addBreadcrumb(debugInfo);
  }
}

export const traceError = (error: unknown, fallbackDebugInfo: Trace): void =>
  trace(isError(error) ? error : fallbackDebugInfo);

function traceDev(debugInfo: Trace) {
  const maybeBreadcrumb = debugInfo as CategorizedBreadcrumb;
  if (
    debugInfo instanceof Error ||
    maybeBreadcrumb?.level === 'error' ||
    maybeBreadcrumb?.level === 'fatal'
  ) {
    const stackError = new Error('empty');
    const stackEntries = stackError.stack?.split('\n');
    const callSite = stackEntries ? stackEntries.at(-1) : '';
    console.error('[trace]', debugInfo, callSite);
  } else if (maybeBreadcrumb?.level === 'warning') {
    console.warn('[trace]', debugInfo);
  } else {
    console.debug('[trace]', debugInfo);
  }
}

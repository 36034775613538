import { ComponentType, lazy, ReactNode, Suspense } from 'react';

interface ILazyComponentProps {
  /**
   * Fallback component rendered while the imported component is loading.
   * Defaults to `null`.
   */
  fallback?: ReactNode;
  /**
   * Accepts a callback that returns a dynamic import statement.
   * The lazy component should be the default export of the file.
   */
  importFunc: () => Promise<{ default: ComponentType }>;
}

/**
 * Lazily loads a component using [React Suspense](https://react.dev/reference/react/Suspense) to reduce bundle sizes.
 */
export function LazyComponent({
  fallback = null,
  importFunc,
}: ILazyComponentProps) {
  const LazyComponent = lazy(importFunc);

  return (
    <Suspense fallback={fallback}>
      <LazyComponent />
    </Suspense>
  );
}

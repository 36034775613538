import { IsString, ValidateNested } from 'class-validator';
import { AiScoringResponseScoreDto } from 'src/services/ai/aiScoringResponseScore.dto';

export class AiScoringResponseDto {
  @IsString()
  readonly summary: string;

  @ValidateNested()
  readonly scores: AiScoringResponseScoreDto[];

  constructor(dto: AiScoringResponseDto) {
    this.summary = dto.summary;
    this.scores = dto.scores?.map(
      (score) => new AiScoringResponseScoreDto(score),
    );
  }
}

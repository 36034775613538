import { FirebaseOptions, initializeApp } from '@firebase/app';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@firebase/app-check';
import { connectAuthEmulator, getAuth } from '@firebase/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
} from '@firebase/firestore';
import { connectStorageEmulator, getStorage } from '@firebase/storage';
import { isDevEnv } from 'src/env';
import tracker from 'src/services/analytics/tracker';

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string;
  }
}

interface HostPortConfig {
  host: string;
  port: number;
}

interface FirebaseEmulatorConfig {
  auth?: string;
  firebaseStorage?: HostPortConfig;
  firestore?: HostPortConfig;
}

interface FirebaseAppCheckConfig {
  captchaKey?: string;
  appCheckDebugToken?: string;
}

export function initFirebase(
  options: FirebaseOptions,
  emulators: FirebaseEmulatorConfig,
  appCheckConfig: FirebaseAppCheckConfig,
) {
  // console.log({ options });
  const app = initializeApp(options);
  initializeFirestore(app, { localCache: persistentLocalCache() });
  if (appCheckConfig.captchaKey) {
    if (isDevEnv()) {
      self.FIREBASE_APPCHECK_DEBUG_TOKEN =
        appCheckConfig.appCheckDebugToken ?? true;
    }
    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(appCheckConfig.captchaKey),
      isTokenAutoRefreshEnabled: true,
    });
  }
  if (emulators.auth !== undefined) {
    connectAuthEmulator(getAuth(), emulators.auth, { disableWarnings: true });
  }
  if (emulators.firebaseStorage !== undefined) {
    connectStorageEmulator(
      getStorage(),
      emulators.firebaseStorage.host,
      emulators.firebaseStorage.port,
    );
  }
  if (emulators.firestore !== undefined) {
    connectFirestoreEmulator(
      getFirestore(),
      emulators.firestore.host,
      emulators.firestore.port,
    );
  }
  tracker.plugins.enable('firebase');
}

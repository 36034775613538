import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UUID } from 'src/@types/common';
import { flushSessionData } from '../commonActions';
import { useTypedSelector } from '../useTypedSelector';

const initialState: UUID[] = [];

const slice = createSlice({
  name: 'hiddenOutcomeIds',
  initialState,
  reducers: {
    hideItem(state, action: PayloadAction<UUID>) {
      state.push(action.payload);
    },
    dropItem(state, action: PayloadAction<UUID>) {
      return state.filter((itemId) => itemId !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(flushSessionData, () => initialState);
  },
});

export const { reducer } = slice;

/**
 * @returns An array of outcome IDs that shouldn't be rendered,
 * because they are being deleted.
 *
 * During deletion, the item(s) should be hidden, because if the user
 * triggered the deletion with a drag-drop gesture, there will be a flicker
 * when the drop has finished, while the async deletion progress is still in progress.
 */
export function useGetHiddenOutcomeIds() {
  return useTypedSelector((state) => state.activeSession.hiddenOutcomeIds);
}

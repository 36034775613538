import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useTypedAutofillParams } from 'src/app/router/hooks';
import { PathParams } from './routeValidation';
import { buildPath, Path } from './routes';

/**
 * A type-safe implementation of the `useNavigate` hook from `react-router-dom`.
 * It automatically fills in the workspace and flow slugs, and the flow instance ID if they are not provided.
 * @returns A type-safe implementation of the `useNavigate` hook from `react-router-dom`
 */
export default function useTypedNavigate(autoFill: boolean = true) {
  const navigate = useNavigate();
  const autofilledParams = useTypedAutofillParams(undefined, autoFill);

  return useCallback(
    function typedNavigate(
      to: Path,
      pathParams: PathParams = {},
      options?: NavigateOptions,
    ) {
      const url = buildPath(to, { ...autofilledParams, ...pathParams });
      return navigate(url, options);
    },
    [navigate, autofilledParams],
  );
}

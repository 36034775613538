import {
  ValidationArguments,
  ValidationError,
  ValidationOptions,
  registerDecorator,
} from 'class-validator';

export function gatherValidationErrorConstraints(errors: ValidationError[]) {
  const constraintsByField: Record<string, Record<string, string>> = {};

  errors.forEach((error) => {
    if (!error.constraints) {
      return;
    }

    const field = error.property;
    if (!constraintsByField[field]) {
      constraintsByField[field] = {};
    }

    const constraints = constraintsByField[field] as Record<string, string>;
    Object.keys(error.constraints).forEach((key) => {
      constraints[key] = (error.constraints as never)[key] as string;
    });
  });

  return constraintsByField;
}

/**
 * **GPT generated** Factory function to create custom class-validator decorators.
 *
 * @param name - The name of the custom validator.
 * @param validateFn - The function that validates the property value.
 * @param defaultMessageFn - The function that returns the default error message.
 * @param validationOptions - Optional validation options to customize error messages and behaviors.
 * @param constraints - Optional array of constraints to pass to the validator.
 *
 * @returns A decorator function to be used on class properties.
 *
 * @example
 *  function IsInRange(
 *    min: number,
 *    max: number,
 *    validationOptions?: ValidationOptions,
 *  ) {
 *    return createCustomValidator(
 *     'isInRange',
 *     (value) => typeof value === 'number' && isInRange(value, min, max),
 *     (args) => {
 *       if (!args?.constraints || !Array.isArray(args?.constraints)) {
 *        throw new Error('[minValue, maxValue] constraints must be set');
 *       }
 *       const [min, max] = args.constraints;
 *       return `$property must be between ${min} and ${max}`;
 *     },
 *    validationOptions,
 *    [min, max],
 *   );
 * }
 */
export function createCustomValidator(
  name: string,
  validateFn: (value: unknown, args?: ValidationArguments) => boolean,
  defaultMessageFn: (args?: ValidationArguments) => string,
  validationOptions?: ValidationOptions,
  constraints: unknown[] = [],
) {
  return (object: object, propertyName: string) => {
    registerDecorator({
      name,
      target: object.constructor,
      propertyName,
      constraints,
      options: validationOptions,
      validator: {
        validate: validateFn,
        defaultMessage: defaultMessageFn,
      },
    });
  };
}

import { validateSync, ValidationOptions } from 'class-validator';
import { AiScoringResponseDto } from 'src/services/ai/aiScoringResponse.dto';
import { createCustomValidator } from 'src/services/database/validators/utils';

/**
 * Validates an AI Scoring Response.
 * @param value The value to validate.
 */
export function isAiScoringResponse(value: unknown): boolean {
  return (
    !!value &&
    typeof value === 'object' &&
    validateSync(new AiScoringResponseDto(value as AiScoringResponseDto))
      .length === 0
  );
}

/**
 * Decorator that validates an AI Scoring Response.
 */
export function IsAiScoringResponse(validationOptions?: ValidationOptions) {
  return createCustomValidator(
    'IsAiScoringResponse',
    isAiScoringResponse,
    (args) => `${args?.property} must be an AI Scoring Response object`,
    validationOptions,
  );
}

interface IIsAiResponseOptions {
  isStringAllowed?: boolean;
  isScoringResponseAllowed?: boolean;
}

/**
 * Validates an {@link AiResponse}.
 */
export function isAiResponse(
  value: unknown,
  options: IIsAiResponseOptions = {},
): boolean {
  const { isStringAllowed = true, isScoringResponseAllowed = true } = options;
  return (
    !!value &&
    ((isStringAllowed && typeof value === 'string') ||
      (isScoringResponseAllowed && isAiScoringResponse(value)))
  );
}

/**
 * Decorator that validates an AI Response.
 * @see {@link AiResponse}
 */
export function IsAiResponse(
  options: IIsAiResponseOptions = {},
  validationOptions?: ValidationOptions,
) {
  return createCustomValidator(
    'IsAiResponse',
    (value) => isAiResponse(value, options),
    (args) => `${args?.property} must be an AI Response object`,
    validationOptions,
  );
}

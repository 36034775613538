import { CSSProperties } from 'react';
import { CSSLengthUnit } from 'src/@types/common';
import { disk1, disk2 } from './Disk.css';

interface IDiskProps {
  color: CSSProperties['color'];
  size: CSSLengthUnit;
}

export function Disk({ color, size }: IDiskProps) {
  return (
    <>
      <span
        className={disk1}
        style={{ width: size, height: size, backgroundColor: color }}
      />
      <span
        className={disk2}
        style={{ width: size, height: size, backgroundColor: color }}
      />
    </>
  );
}

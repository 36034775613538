import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedParams } from 'src/app/router/hooks';
import { actions as activeActions } from 'src/state/active/index';
import { selectActive } from 'src/state/active/selectors';
import { useTypedSelector } from 'src/state/useTypedSelector';

/**
 * Returns the active state parameters.
 */
export function useActiveState() {
  return useTypedSelector(selectActive);
}

type ActiveStateParamKey = keyof ReturnType<typeof selectActive>;

/**
 * Returns the active state parameter for a specific key.
 * @param param - The parameter key to retrieve.
 */
export function useActiveParam(param: ActiveStateParamKey) {
  return useActiveState()[param];
}

/**
 * Initializes the active store with the parameters from the URL.
 */
export function useActiveStoreInit() {
  const dispatch = useDispatch();
  const params = useTypedParams();

  useEffect(() => {
    dispatch(
      activeActions.setActiveState({
        workspaceId: params.workspaceId,
        flowDefinitionId: params.flowDefinitionId,
        // TODO hard-coded path param fallback
        flowInstanceId: params.flowInstanceId ?? params.shareId,
        sessionId: params.sessionId,
      }),
    );
  }, [
    dispatch,
    params.workspaceId,
    params.flowDefinitionId,
    params.flowInstanceId,
    params.sessionId,
    params.shareId,
  ]);
}

import {
  Equals,
  IsArray,
  IsEnum,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { IsStringOrNumber } from 'src/services/database/validators/validators';

/**
 * Describes the inputs rendered for the user
 * in the session start/end survey forms.
 */
export enum SurveyInputType {
  Text = 'text',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Reflection = 'reflection',
}

//#region  =========== Text survey ===========

/** Defines the behavior of the rendered text input. */
export class SurveyTextInputDto {
  /** Stringified HTML rendered for the user as info about the input. */
  @IsString()
  readonly description: string;

  /** Optional input placeholder. */
  @IsOptional()
  @IsString()
  readonly placeholder?: string;

  /** Determines the input type, and therefore the data it can contain. */
  @IsEnum(SurveyInputType)
  readonly type: SurveyInputType.Text;

  constructor(dto: SurveyTextInputDto) {
    this.description = dto.description;
    this.placeholder = dto.placeholder;
    this.type = dto.type;
  }
}

//#endregion  ======== Text survey ===========

//#region  =========== Checkbox survey ===========

/**
 * Describes a checkbox value used in session survey modals,
 * when the modal form uses checkbox inputs.
 */
export class SurveyCheckboxTextChoiceDto {
  /** Form value of the input choice. */
  @IsStringOrNumber()
  readonly value: string | number;

  /**
   * The rendered label for the input choice.
   * If `emoji` is set, the label becomes the ARIA label
   * for the rendered emoji character.
   */
  @IsStringOrNumber()
  readonly label: string | number;

  constructor(dto: SurveyCheckboxTextChoiceDto) {
    this.value = dto.value;
    this.label = dto.label;
  }
}

/**
 * Describes a checkbox value used in session survey modals,
 * when the modal form uses checkbox inputs.
 *
 * Instead of a text label, a single emoji character is rendered,
 * and styled differently from text labels.
 */
export class SurveyCheckboxEmojiChoiceDto extends SurveyCheckboxTextChoiceDto {
  /** If set, the emoji character will be rendered instead of the label. */
  @IsString()
  readonly emoji: string;

  constructor(dto: SurveyCheckboxEmojiChoiceDto) {
    super(dto);
    this.emoji = dto.emoji;
  }
}

/**
 * Describes a checkbox input group used in session survey modals.
 * Simple strings or single emoji characters are supported as checkbox variations.
 */
export class SurveyCheckboxInputDto {
  @IsEnum(SurveyInputType)
  readonly type: SurveyInputType.Checkbox;

  @IsArray()
  @ValidateNested({ each: true })
  readonly choices: Array<
    SurveyCheckboxTextChoiceDto | SurveyCheckboxEmojiChoiceDto
  >;

  constructor(dto: SurveyCheckboxInputDto) {
    this.type = dto.type;
    this.choices = dto.choices.map((choice) => {
      if ('emoji' in choice) {
        return new SurveyCheckboxEmojiChoiceDto(choice);
      } else if (choice.value) {
        return new SurveyCheckboxTextChoiceDto(choice);
      } else {
        console.error(choice);
        throw new Error('Invalid choice type');
      }
    });
  }
}

//#endregion  ======== Checkbox survey ===========

//#region  =========== Radio survey ===========

/**
 * Describes a radio input choice used in session survey modals.
 */
export class RadioSurveyChoiceDto {
  @IsStringOrNumber()
  readonly value: string | number;

  /** Single-word input label rendered for the user. */
  @IsStringOrNumber()
  readonly label: string | number;

  /** Optional stringified HTML rendered for the user apart from the label. */
  @IsOptional()
  @IsString()
  readonly description?: string;

  constructor(dto: RadioSurveyChoiceDto) {
    this.value = dto.value;
    this.label = dto.label;
    this.description = dto.description;
  }
}

// TODO [OM] replace this with image or SVG URLs
export enum SurveyIconSetId {
  CompetenceFeedback = 'competenceFeedback',
}

/**
 * Describes a radio input group used in session survey modals.
 */
export class SurveyRadioInputDto {
  /** Determines the input type, and therefore the data it can contain. */
  @IsEnum(SurveyInputType)
  readonly type: SurveyInputType.Radio;

  /** Defines the possible radio choices. */
  @IsArray()
  @ValidateNested({ each: true })
  readonly choices: RadioSurveyChoiceDto[];

  /** Selects an available icon set that will be used to render an icon for each radio choice. */
  @IsEnum(SurveyIconSetId)
  readonly iconSet: SurveyIconSetId;

  constructor(dto: SurveyRadioInputDto) {
    this.type = dto.type;
    this.choices = dto.choices.map(
      (choice) => new RadioSurveyChoiceDto(choice),
    );
    this.iconSet = dto.iconSet;
  }
}

//#endregion  ======== Radio survey ===========

//#region  =========== Reflection survey ===========

export class SurveyReflectionInputDto {
  /** Determines the input type, and therefore the data it can contain. */
  @IsEnum(SurveyInputType)
  readonly type: SurveyInputType.Reflection;

  /** Lowest possible input value */
  @Equals(1)
  readonly minValue: 1;

  /** Highest possible input value */
  @Equals(5)
  readonly maxValue: 5;

  constructor(dto: SurveyReflectionInputDto) {
    this.type = dto.type;
    this.maxValue = dto.maxValue;
    this.minValue = dto.minValue;
  }
}

//#endregion  ======== Reflection survey ===========

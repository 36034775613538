import { IsOptional, IsString, MinLength } from 'class-validator';

/**
 * Defines metadata of a flow definition author.
 */
export class FlowDefinitionAuthorDto {
  @MinLength(1)
  readonly avatarUrl: string;

  @MinLength(1)
  readonly displayName: string;

  @IsString()
  @IsOptional()
  readonly company?: string;

  @IsString()
  @IsOptional()
  readonly jobTitle?: string;

  constructor(dto: FlowDefinitionAuthorDto) {
    this.avatarUrl = dto.avatarUrl;
    this.displayName = dto.displayName;
    this.company = dto.company;
    this.jobTitle = dto.jobTitle;
  }
}

const STAGING_TLD = import.meta.env.VITE_STAGING_TLD;
const PROD_TLD = import.meta.env.VITE_PROD_TLD;
const MODE = import.meta.env.MODE;

/**
 * @returns `true` if the Node env is set to `development`,
 * or if the app is served from `localhost`.
 */
export const isDevEnv = () =>
  MODE === 'development' || window.location.hostname === 'localhost';

/**
 * @returns `true` if the current URL ends with the staging top-level domain,
 * and the environment variable is set to `production`.
 * This is set as the `VITE_STAGING_TLD` environment variable.
 */
export const isStagingEnv = () =>
  MODE === 'production' &&
  window &&
  window.location.hostname.endsWith(STAGING_TLD);

/**
 * @returns `true` if the current URL ends with the production top-level domain,
 * and the environment variable is set to `production`.
 * This is set as the `VITE_PROD_TLD` environment variable.
 */
export const isProdEnv = () =>
  MODE === 'production' &&
  window &&
  window.location.hostname.endsWith(PROD_TLD);

/**
 * @returns `true` if Firebase is connected to its local emulator
 */
export const hasFirebaseEmulator = () =>
  import.meta.env.VITE_FIREBASE_EMU === 'true';

/**
 * Gets the last part of the top-level domain from the window's hostname.
 * @returns {string} The last part of the top-level domain or an empty string if not available.
 */
export function getTopLevelDomain(): string {
  const parts = (window.location?.hostname || '').split('.');
  return parts.pop() || '';
}

/**
 * Gets the CDN URL for a given path.
 * @param path The path to the file.
 * @returns The full URL to the file.
 */
export function getCdnUrl(path: string): string {
  return `${import.meta.env.VITE_CDN_BASEURL}${path.replace(/^\//, '')}`;
}

import { setUser } from '@sentry/react';
import { UUID } from 'src/@types/common';

/**
 * Set the telemetry user id within Sentry.
 * If the userId is not provided, the user will be unset.
 * @param userId - The user id to set.
 * @see setUser
 */
export function setTelemetryUserId(userId?: UUID) {
  if (userId) {
    setUser({ id: userId });
  } else {
    setUser(null);
  }
}

import { clsx } from 'clsx';
import { CSSProperties } from 'react';
import { CSSLengthUnit } from 'src/@types/common';
import { posRela, screenReaderOnly } from 'src/styles/utils.css';
import { CircleBall } from './CircleBall';
import { Disk } from './Disk';

interface ISpinnerProps {
  className?: string;
  /** Defaults to `currentColor` */
  color?: CSSProperties['color'];
  /** Diameter of the spinner component. Defaults to `100%`. */
  size?: CSSLengthUnit;
  style?: CSSProperties;
  type: 'circleBall' | 'disk';
}

/**
 * Renders a circular spinner component.
 * Based on https://www.davidhu.io/react-spinners/
 */
export function Spinner({
  className,
  color = 'currentColor',
  size = '100%',
  style,
  type,
}: ISpinnerProps) {
  return (
    <div
      className={clsx(posRela, className)}
      style={{
        ...style,
        width: size,
        height: size,
      }}
    >
      <span className={screenReaderOnly} aria-busy="true" aria-live="polite">
        Loading.
      </span>
      {type === 'disk' && <Disk color={color} size={size} />}
      {type === 'circleBall' && <CircleBall color={color} size={size} />}
    </div>
  );
}

import { IsArray, IsNumber, IsString, ValidateNested } from 'class-validator';
import { LocaleMap, TextObject } from 'src/@types/common';
import { FlowDefinitionModuleContentDto } from 'src/services/database/FlowDefinitions/dto/flowDefinitionModuleContent.dto';
import { IsLocaleText } from 'src/services/database/validators/validators';

/**
 * Defines a flow module, which contains child sessions
 */
export class FlowDefinitionModuleDto {
  @IsString()
  /** Unique identifier of the flow module */
  readonly id: string;

  @IsNumber()
  /** Order of the module in the flow */
  readonly order: number;

  @IsArray()
  @ValidateNested({ each: true })
  /** Ordered list of module content. */
  readonly content: FlowDefinitionModuleContentDto[];

  @IsLocaleText()
  /** Module title rendered for the user */
  readonly title: LocaleMap<TextObject>;

  @IsString()
  /** URL of the thumbnail image appearing in the flow navigation sidebar */
  readonly thumbnailUrl: string;

  constructor(dto: FlowDefinitionModuleDto) {
    this.id = dto.id;
    this.order = dto.order;
    this.content = dto.content?.map(
      (content) => new FlowDefinitionModuleContentDto(content),
    );
    this.title = dto.title;
    this.thumbnailUrl = dto.thumbnailUrl;
  }
}

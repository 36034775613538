import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  generateGuestUserDisplayName,
  getGuestUserAvatar,
} from 'src/services/auth/utils';
import { userLogout } from 'src/state/commonActions';
import { UserPartialPayload, UserState } from './types';

const initialState: UserState = {
  user: {
    uid: '',
    email: '',
    displayName: '',
    avatar: '',
  },
  isLoggedIn: false,
  isGuestUser: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserPartialPayload>) => {
      if (action.payload.uid) {
        state.user.uid = action.payload.uid;
        state.user.avatar = getGuestUserAvatar(action.payload.uid);
      }

      if (action.payload.email !== undefined) {
        state.user.email = action.payload.email ?? '';
      }

      if (action.payload.isAnonymous !== undefined) {
        state.user.displayName =
          action.payload.isAnonymous || action.payload.displayName === null
            ? generateGuestUserDisplayName(action.payload.uid ?? state.user.uid)
            : action.payload.displayName ?? state.user.displayName;

        state.isGuestUser = action.payload.isAnonymous;
      } else if (action.payload.displayName !== undefined) {
        state.user.displayName = action.payload.displayName ?? '';
      }

      state.isLoggedIn = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => initialState);
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;

import { IsBoolean, IsString } from 'class-validator';

export class WorkspaceInvitationSettingsDto {
  @IsBoolean()
  readonly allowGuests: boolean;

  @IsString({ each: true })
  readonly allowEmailDomains: string[];

  @IsBoolean()
  readonly requireInvitationId: boolean;

  // TODO [KS] Default values should be `allowGuests: false`, `allowEmailDomains: []`, `requireInvitationId: true`,
  //  and will need to be updated when we have an admin UI to update these settings.
  constructor(dto: WorkspaceInvitationSettingsDto) {
    this.allowGuests = dto.allowGuests ?? true;
    this.allowEmailDomains = dto.allowEmailDomains ?? [];
    this.requireInvitationId = dto.requireInvitationId ?? false;
  }
}

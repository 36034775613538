/**
 * Use this enum for the `contentType` property of any `CanvasContentDto` that the user can create.
 * This may include draggable elements, or static/embedded items
 * that store the user's content in the database.
 */
export enum CanvasContentType {
  /**
   * Represents a `<CanvasNote/>` content item on the canvas, or in the database.
   */
  CanvasNote = 'note',
  CanvasCard = 'card',
}

/**
 * Use this enum for the `contentType` property of any interactive canvas elements
 * that aren't created by the user. These elements are usually stateful,
 * and therefore need to be stored in the database similarly to regular canvas content.
 */
export enum CanvasWidgetType {
  /**
   * Represents a `<CardStack/>` widget on the canvas, or in the database.
   */
  CardStack = 'cardStack',
  /**
   * Represents a `<DropPanel/>` widget on the canvas, or in the database.
   */
  DropPanel = 'dropPanel',
  /**
   * Represents a `<ScoringGrid/>` widget on the canvas, or in the database.
   */
  ScoringGrid = 'scoringGrid',
  /**
   * Represents a `<Table/>` widget on the canvas, or in the database.
   */
  Table = 'canvasTable',
  /**
   * Represents a drop zone with a fixed layout, where children are arranged
   * into an expandable stack (i.e. items aren't positioned with X-Y coordinates).
   */
  StackedDropZone = 'stackedDropZone',
}

/**
 * Currently only used by the flow editor to tie draggable toolbar icons to widget types.
 *
 * Depending on the implementation, this enum can be used as the ID of singletons,
 * or as content type when instantiating new widgets.
 */
export enum CanvasToolbarType {
  /**
   * Represents a `<DropPanel/>` draggable item on a toolbar.
   */
  DropPanel = 'toolbar.dropPanel',
  /**
   * Represents a `<ScoringGrid/>` draggable item on a toolbar.
   */
  ScoringGrid = 'toolbar.scoringGrid',
}

export const CanvasAnyType = { ...CanvasContentType, ...CanvasWidgetType };

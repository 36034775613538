import { IsNumber, IsString } from 'class-validator';

export class AiScoringResponseScoreDto {
  /** Title of the AI response object. */
  @IsString()
  readonly title: string;

  /** Content of the AI response object. */
  @IsString()
  readonly description: string;

  /** An optional score of the AI response object. */
  @IsNumber()
  readonly score?: number;

  constructor(dto: AiScoringResponseScoreDto) {
    this.title = dto.title;
    this.description = dto.description;
    this.score = dto.score;
  }
}

import { IsNumber, IsOptional, IsString } from 'class-validator';

/**
 * Definition of module content.
 */
export class FlowDefinitionModuleContentDto {
  @IsString()
  /** Unique identifier of the module content */
  readonly id: string;

  @IsNumber()
  /** Order of the module content in the module */
  readonly order: number;

  @IsOptional()
  @IsString()
  /** Will be used in session definitions created in the flow editor */
  readonly sessionBundleId?: string;

  @IsOptional()
  @IsString()
  /** Will be used in session definitions created in the flow editor */
  readonly sessionVersionId?: string;

  constructor(dto: FlowDefinitionModuleContentDto) {
    this.id = dto.id;
    this.order = dto.order;
    this.sessionBundleId = dto.sessionBundleId;
    this.sessionVersionId = dto.sessionVersionId;
  }
}

import {
  ValidationOptions,
  isBoolean,
  isObject,
  isString,
} from 'class-validator';
import {
  OpenAiResponseSchemaArrayElement,
  OpenAiResponseSchemaObjectElement,
  OpenAiResponseSchemaPrimitiveElement,
  OpenAiResponseSchemaType,
} from 'src/services/ai/openAiResponseSchema.type';
import { createCustomValidator } from 'src/services/database/validators/utils';
import {
  isValidType,
  validateArrayEvery,
  validateObjectEvery,
} from 'src/utils/typeAssertions/common';

function isOpenAiResponseSchemaObject(value: unknown) {
  return isValidType<OpenAiResponseSchemaObjectElement>(value, [
    ['type', (val) => val === OpenAiResponseSchemaType.Object],
    ['properties', isObject],
    ['required', validateArrayEvery(isString)],
    ['additionalProperties', isBoolean],
  ]).isValid;
}

function isOpenAiResponseSchemaArray(value: unknown): boolean {
  return isValidType<OpenAiResponseSchemaArrayElement>(value, [
    ['type', (val) => val === OpenAiResponseSchemaType.Array],
    ['items', validateObjectEvery(isOpenAiResponseSchema)],
  ]).isValid;
}

function isOpenAiResponseSchemaPrimitive(value: unknown) {
  return isValidType<OpenAiResponseSchemaPrimitiveElement>(value, [
    [
      'type',
      (val) =>
        [
          OpenAiResponseSchemaType.String,
          OpenAiResponseSchemaType.Number,
          OpenAiResponseSchemaType.Boolean,
        ].includes(val as OpenAiResponseSchemaType),
    ],
  ]).isValid;
}

export function isOpenAiResponseSchema(value: unknown) {
  return (
    isOpenAiResponseSchemaObject(value) ||
    isOpenAiResponseSchemaArray(value) ||
    isOpenAiResponseSchemaPrimitive(value)
  );
}

/**
 * Decorator for validating an OpenAI response schema.
 */
export function IsOpenAiResponseSchema(validationOptions?: ValidationOptions) {
  return createCustomValidator(
    'IsOpenAiResponseSchema',
    isOpenAiResponseSchema,
    () => 'Invalid OpenAI response schema',
    validationOptions,
  );
}

import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useWorkspaceCol } from 'src/services/database/Workspaces/getters';
import Error500 from 'src/app/pages/Error500';
import { Path } from 'src/app/router/routes';
import { useWorkspaceId } from 'src/utils/resource.hooks';
import { PageLoadingSpinner } from 'src/app/components/shared/PageLoadingSpinner/PageLoadingSpinner';
import { TypedNavigate } from 'src/app/router/TypedNavigate/TypedNavigate';
import { REDIRECT_PARAM } from 'src/app/router/guards/AuthRouteGuard';

/**
 * Ensures that the user has at least one workspace. If the user does not have
 * any workspaces, they will be redirected to the workspace creation page.
 * If the user has at least one workspace, the children will be rendered.
 * @returns The children if the user has at least one workspace, otherwise
 * a 500 error page if there was an error fetching the workspace count.
 */
export function EnsureWorkspaceGuard({ children }: PropsWithChildren) {
  const workspaceId = useWorkspaceId();
  const { result, error } = useWorkspaceCol();

  if (result === undefined && error === undefined) {
    return (
      <PageLoadingSpinner>
        <div>Preparing your workspace...</div>
      </PageLoadingSpinner>
    );
  }

  if (error) {
    // TODO [KS] Maybe add a custom error message
    return <Error500 />;
  }

  // Result is available here as error is undefined
  // TODO [KS] Change PathParams to work properly with optional params
  if (result!.docs.length === 0) {
    if (workspaceId) {
      return (
        <TypedNavigate
          to={Path.WorkspaceJoinViaInvitation}
          params={{
            workspaceJoinId: workspaceId,
            'invitationId?': '',
          }}
          query={{ [REDIRECT_PARAM]: window.location.pathname }}
          replace
        />
      );
    }

    return <Navigate to={Path.WorkspaceCreate} replace />;
  }

  return children;
}

import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { useEffect, useMemo, useState } from 'react';
import { VITE_SYSADMIN_EMAIL_DOMAIN } from 'src/env';
import { useTypedSelector } from 'src/state/useTypedSelector';
import { useSetUser, useUser } from 'src/utils/userContent/hooks';

export enum UserAuthStatus {
  Uninitialized,
  Initializing,
  Success,
  Failed,
}

/**
 * The hook is responsible for fetching a user from Firebase. If there is a
 * valid user (guest or registered), it will return a success status. Otherwise,
 * it will return a failed status.
 *
 * The hook should be called at the app's top-level component.
 * @returns The status of the base user data fetching process status.
 * @see {@link UserAuthStatus}
 */
export default function useUserAuthState() {
  const setUser = useSetUser();
  const [userStatus, setUserStatus] = useState(UserAuthStatus.Uninitialized);

  useEffect(
    function onUserAuthChangeEffect() {
      if (userStatus === UserAuthStatus.Uninitialized) {
        setUserStatus(UserAuthStatus.Initializing);
      }

      return onAuthStateChanged(getAuth(), (firebaseUser) => {
        if (firebaseUser?.uid) {
          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            displayName: firebaseUser.displayName,
            isAnonymous: firebaseUser.isAnonymous,
          });

          setUserStatus(UserAuthStatus.Success);
        } else {
          setUserStatus(UserAuthStatus.Failed);
        }
      });
    },
    [setUser, userStatus],
  );

  // Using memo to avoid re-rendering the app component when the status does not
  // change due to returning a new array reference.
  return useMemo(() => userStatus, [userStatus]);
}

/**
 * Returns the current user state. Internal use only.
 */
function useUserState() {
  return useTypedSelector((state) => state.user);
}

/**
 * Returns whether the current user is a guest user.
 * @returns `true` if the user is a guest user, otherwise `false`.
 */
export function useIsGuestUser() {
  return useUserState()?.isGuestUser ?? false;
}

/**
 * @returns `true` if the user has system admin privilege
 * based on the Firebase auth data.
 */
export function useIsUserSystemAdmin() {
  return useUser().email?.includes(VITE_SYSADMIN_EMAIL_DOMAIN);
}

/**
 * Returns whether the current user is logged in.
 * @returns `true` if the user is logged in, otherwise `false`.
 */
export function useIsUserLoggedIn() {
  return useUserState()?.isLoggedIn ?? false;
}

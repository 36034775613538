import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../useTypedSelector';

const initialState = {
  wasCompletedDuringThisVisit: false,
  isConfettiAnimating: false,
  hasAutoTriggered: false,
};

const slice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    setAutoTriggeredOnce(state) {
      state.hasAutoTriggered = true;
    },
    setCompletedFlow(state) {
      state.wasCompletedDuringThisVisit = true;
    },
    setIsConfettiAnimating(state, action: PayloadAction<boolean>) {
      state.isConfettiAnimating = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

const { actions, reducer } = slice;

/**
 * @returns Memoized callback that allows the automatic confetti explosion to happen.
 */
function useAllowAutoConfetti() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(actions.setCompletedFlow());
  }, [dispatch]);
}

/**
 * @returns Memoized callback that launches confetti!
 */
function useFireConfetti() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(actions.setIsConfettiAnimating(true));
  }, [dispatch]);
}

/**
 * @returns Memoized callback that triggers hiding the confetti component.
 * The component should be hidden to allow for repeated, manual triggering.
 */
function useEndConfetti() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(actions.setIsConfettiAnimating(false));
  }, [dispatch]);
}

/**
 * @returns `true` if the confetti fired on its own at least once
 */
function useAutoTriggerState() {
  return useTypedSelector((state) => state.flow.completion.hasAutoTriggered);
}

/**
 * @returns Memoized callback for preventing auto-firing confetti in the future,
 * e.g. in the case of a component re-render on viewport orientation change.
 */
function useSignalAutoTriggered() {
  const dispatch = useDispatch();
  return useCallback(
    function signalAutoTriggered() {
      dispatch(actions.setAutoTriggeredOnce());
    },
    [dispatch],
  );
}

/**
 * Reverts confetti behavior to its initial state. This is necessary when the
 * user is switching flows, in order to avoid stale state. (duplicate trigger, etc.)
 * @returns Memoized callback
 */
function useResetConfettiState() {
  const dispatch = useDispatch();
  return useCallback(
    function resetConfettiState() {
      dispatch(actions.reset());
    },
    [dispatch],
  );
}

export {
  reducer,
  useAllowAutoConfetti,
  useAutoTriggerState,
  useEndConfetti,
  useFireConfetti,
  useResetConfettiState,
  useSignalAutoTriggered,
};

import { IsEnum, MinLength } from 'class-validator';

/** Workspace Member Role is used to represent the role of a workspace member. */
export enum WorkspaceMemberRole {
  Admin = 'admin',
  Member = 'member',
}

/** Workspace Member DTO is used to represent and validate a workspace member. */
export class WorkspaceMemberDto {
  @MinLength(1)
  readonly id: string;

  @MinLength(1)
  readonly displayName: string;

  @IsEnum(WorkspaceMemberRole)
  readonly role: WorkspaceMemberRole;

  constructor(dto: WorkspaceMemberDto) {
    this.id = dto.id;
    this.displayName = dto.displayName;
    this.role = dto.role;
  }
}

import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Path } from 'src/app/router/routes';
import { useUserId } from 'src/utils/userContent/hooks';

// TODO Implement overlays that were previously in ProtectedRouteElement?

/**
 * Query param key for storing the redirect target path after successful authentication.
 */
export const REDIRECT_PARAM = 'redirectTo';

/**
 * Redirects the user to the authentication page if they are not logged in.
 * @param children - The children to render if the user is authenticated.
 * @returns The children if the user is authenticated, otherwise null.
 */
export function AuthRouteGuard({ children }: PropsWithChildren) {
  const userId = useUserId();
  const location = useLocation();

  return userId ? (
    children
  ) : (
    <Navigate
      to={`${Path.Auth}?${REDIRECT_PARAM}=${encodeURIComponent(
        location.pathname + location.search,
      )}`}
    />
  );
}

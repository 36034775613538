import { FirestoreDataConverter, SetOptions } from '@firebase/firestore';
import { isObject } from 'class-validator';
import { cloneObject } from 'src/utils/object';
import { UnknownPartial } from '../types';
import { sanitizeFirestoreData, validateSyncAndThrow } from '../utils';
import { CanvasContentDto } from './dto/canvasContent.dto';

// TODO could not use createValidatorConverter directly because of TS error.
// Is it because of the extended class?

/**
 * @returns A typed Firestore data converter that validates the payload
 * by calling class-validator with {@link CanvasContentDto}.
 *
 * The converter throws `Error` if validation fails.
 * @template TContent - The content's custom data, which currently isn't validated
 */
export function getCanvasContentConverter<
  TContent extends UnknownPartial,
>(): FirestoreDataConverter<CanvasContentDto<TContent>> {
  return {
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      const dto = new CanvasContentDto<TContent>(
        data as CanvasContentDto<TContent>,
      );
      validateSyncAndThrow(dto);
      return dto;
    },

    toFirestore(data, options?: SetOptions) {
      const isPartialData =
        isObject(options) && ('merge' in options || 'mergeFields' in options);

      const dto = new CanvasContentDto<TContent>(
        data as CanvasContentDto<TContent>,
      );
      validateSyncAndThrow(dto, { skipMissingProperties: isPartialData });

      return sanitizeFirestoreData(cloneObject(data));
    },
  };
}

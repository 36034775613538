import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { UUID } from 'src/@types/common';
import { Size } from 'src/@types/dnd';
import { flushSessionData } from '../commonActions';
import { IRootState } from '../types';

type SetSizeAction = readonly [id: UUID, size: Size];

const initialState: Record<UUID, Size> = {};

const slice = createSlice({
  initialState,
  name: 'sizes',
  reducers: {
    setSize(state, action: PayloadAction<SetSizeAction>) {
      const [id, size] = action.payload;
      state[id] = size;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(flushSessionData, () => initialState),
});

const { actions, reducer } = slice;

/**
 * Allows storing the size of a canvas item, so it can be used in
 * drag-drop calculations. One such use case is e.g. preventing the
 * dragged item from overflowing the borders of the canvas area.
 *
 * Sizes with 0 values are not dispatched. This helps avoid visual bugs,
 * and unnecessary re-renders in case an item isn't done rendering yet.
 * @returns Memoized callback for storing the size of a canvas item
 */
function usePublishOwnSize(itemId: UUID) {
  const dispatch = useDispatch();
  const { ref } = useResizeDetector({
    onResize: ({ width, height }) => {
      if (width && width > 0 && height && height > 0) {
        dispatch(actions.setSize([itemId, { width, height }]));
      }
    },
    refreshRate: 100,
  });
  return ref;
}

/**
 * Returns the width and height of the canvas item, so it can be used in
 * drag-drop calculations. One such use case is e.g. preventing the
 * dragged item from overflowing the borders of the canvas area.
 * @param id - The canvas item ID
 * @returns The width and height of the canvas item
 */
function useGetSize(id: UUID) {
  const sizeSelector = useCallback(
    function getSize(state: IRootState) {
      return state.activeCanvas.sizes[id];
    },
    [id],
  );
  return useSelector(
    sizeSelector,
    (next, prev) =>
      prev?.width === next?.width && next?.height === prev?.height,
  );
}

export { reducer, useGetSize, usePublishOwnSize };

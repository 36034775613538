import { useCallback, useContext, useEffect, useMemo } from 'react';
import { FocusManagerContext } from '@features/canvas/services/FocusManager/Context';
import {
  FocusManagerLockKey,
  FocusManagerLockOwner,
  FocusManagerLockState,
  FocusManagerLockStateSetterCurried,
  FocusManagerLockStateSetterWithKey,
} from '@features/canvas/services/FocusManager/FocusManager.types';
import { getFocusManagerLockExpirationTime } from '@features/canvas/services/FocusManager/utils';

/**
 * A hook to return the Focus Manager context.
 * @throws An error if the hook is not used within the Focus Manager provider.
 */
export function useFocusManagerContext() {
  const ctx = useContext(FocusManagerContext);
  if (!ctx) {
    throw new Error('Hook must be used within FocusManager.Provider');
  }

  return ctx;
}

/**
 * A hook to check if the Focus Manager is ready.
 */
export function useFocusManagerIsReady() {
  const ctx = useFocusManagerContext();
  return !!ctx.locks;
}

/**
 * A hook to return the Focus Manager locks.
 */
export function useFocusManagerLocks() {
  return useFocusManagerContext().locks;
}

export function useFocusManagerGetLock(key: FocusManagerLockKey) {
  const ctx = useFocusManagerContext();
  return ctx.locks?.find((lock) => lock.key === key);
}

export function useFocusManagerSetLock(): FocusManagerLockStateSetterWithKey {
  const ctx = useFocusManagerContext();
  return useCallback(
    (key, lock, owner, expiry) => {
      if (lock) {
        if (!owner) {
          throw new Error('Owner must be provided to lock a key');
        }

        return ctx.lock(owner, key, expiry);
      } else {
        return ctx.unlock(key);
      }
    },
    [ctx],
    // Function overload resolution didn't work without type casting
  ) as FocusManagerLockStateSetterWithKey;
}

/**
 * @param owner - Owner to look for
 * @returns `true` if the queried owner is actively locking any canvas item.
 */
export function useFocusManagerIsOwnerLocking(owner: string) {
  const allLocks = useFocusManagerLocks();
  return useMemo(
    () => allLocks?.some((lock) => lock.owner === owner),
    [allLocks, owner],
  );
}

/**
 * @returns Memoized callback that erases all locks belonging to a user.
 */
export function useFocusManagerCleanupUserLocks() {
  return useFocusManagerContext().cleanup;
}

/**
 * A hook to use the Focus Manager lock state for a specific key.
 * It returns a tuple with the lock state and a function to lock or unlock the key.
 * @param key - The key to get the lock state for.
 */
export function useFocusManagerLockState(
  key: FocusManagerLockKey,
): FocusManagerLockState {
  const lockItem = useFocusManagerGetLock(key);
  const setLockItem = useFocusManagerSetLock();
  const curriedSetLockItem = useCallback(
    (lock, owner, expiry) => {
      setLockItem(key, lock, owner, expiry);
    },
    [key, setLockItem],
  ) as FocusManagerLockStateSetterCurried;
  return [lockItem, curriedSetLockItem] as const;
}

/**
 * Refreshes the lock on key id, if the owner is the current user and the
 * lock is still active.
 * It refreshes the lock half of the expiry time before the lock expires.
 * @param key - Target key
 * @param owner - Owner of the lock
 * @param expiry - Lock expiry time in milliseconds
 */
export function useFocusManagerLockRefresh(
  key: FocusManagerLockKey,
  owner: FocusManagerLockOwner,
  expiry: number,
) {
  const [lock, setLock] = useFocusManagerLockState(key);

  /**
   * Using useEffect to refresh the lock if the owner is the current user.
   */
  useEffect(() => {
    if (lock?.owner !== owner || !lock?.expiry) {
      return;
    }

    const nextRefresh = lock.expiry - expiry / 2 - Date.now();
    const timeout = setTimeout(() => {
      setLock(true, owner, getFocusManagerLockExpirationTime(expiry));
    }, nextRefresh);

    return () => clearTimeout(timeout);
  }, [key, lock, setLock, owner, expiry]);
}

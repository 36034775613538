import amplitudePlugin from '@analytics/amplitude';
import analytics from 'analytics';
import { isDevEnv, VITE_AMPLITUDE_ID } from 'src/env';
import firebasePlugin from './analytics-firebase';

const plugins: Parameters<typeof analytics>[0]['plugins'] = [];

if (VITE_AMPLITUDE_ID) {
  plugins.push(
    amplitudePlugin({
      apiKey: VITE_AMPLITUDE_ID,
      options: {
        trackingOptions: {
          ip_address: false,
          includeUtm: true,
        },
      },
    }),
  );
} else if (isDevEnv()) {
  console.warn('[analytics] Amplitude ID not found');
}

plugins.push(firebasePlugin());

/**
 * Implements tracking page views and custom user events.
 * @see https://getanalytics.io/
 */
const tracker = analytics({
  debug: process.env.NODE_ENV === 'development',
  plugins,
});

export default tracker;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SidebarAnimationState, SidebarPage } from 'src/@types/session';
import { flushSessionData } from '../commonActions';
import { useTypedSelector } from '../useTypedSelector';

const initialState: {
  animationState: SidebarAnimationState;
  page: SidebarPage | undefined;
  show: boolean;
} = {
  animationState: 'hide',
  page: undefined,
  show: false,
};

const slice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    showPage(state, action: PayloadAction<SidebarPage>) {
      state.show = true;
      state.animationState = 'showing';
      state.page = action.payload;
    },
    showEnd(state) {
      state.animationState = 'show';
    },
    hidePage(state) {
      state.show = false;
      state.animationState = 'hiding';
    },
    hideEnd(state) {
      state.animationState = 'hide';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(flushSessionData, () => initialState);
  },
});

export const { reducer } = slice;
const { actions } = slice;

export function useShowSidebar() {
  const dispatch = useDispatch();
  return useCallback(
    function showSidebar(page: SidebarPage) {
      dispatch(actions.showPage(page));
    },
    [dispatch],
  );
}

export function useShowSidebarEnd() {
  const dispatch = useDispatch();
  return useCallback(
    function showSidebarEnd() {
      dispatch(actions.showEnd());
    },
    [dispatch],
  );
}

export function useHideSidebar() {
  const dispatch = useDispatch();
  return useCallback(
    function hideSidebar() {
      dispatch(actions.hidePage());
    },
    [dispatch],
  );
}

export function useHideSidebarEnd() {
  const dispatch = useDispatch();
  return useCallback(
    function hideSidebarEnd() {
      dispatch(actions.hideEnd());
    },
    [dispatch],
  );
}

export function useGetVisibleSidebarId() {
  return useTypedSelector((state) => state.activeSession.sidebar.page);
}

export function useIsSidebarVisible() {
  return useTypedSelector((state) => state.activeSession.sidebar.show);
}

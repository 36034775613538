import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isTouchDevice, isUserAgentTabletOrMobile } from 'src/utils/device';
import { useTypedSelector } from '../useTypedSelector';

const initialState: {
  isMobile: boolean;
} = {
  isMobile: false,
};

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setIsMobile(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload;
    },
  },
});

const { actions, reducer } = slice;

/**
 * Infers if the app is rendered on a touch device with a small viewport,
 * and stores the boolean in Redux.
 *
 * The criteria are:
 * - touch device, and
 * - user agent indicates mobile or tablet
 */
function useCheckIfMobileEffect() {
  const dispatch = useDispatch();
  const [hasChecked, setHasChecked] = useState(false);
  useEffect(() => {
    if (hasChecked) return;
    const isMobile = isTouchDevice() && isUserAgentTabletOrMobile();
    dispatch(actions.setIsMobile(isMobile));
    setHasChecked(true);
  }, [dispatch, hasChecked]);
}

/**
 * @returns Whether the app is viewed on a mobile device.
 *
 * For criteria, see `useCheckIfMobile()`.
 */
function useGetIsMobile() {
  return useTypedSelector((state) => Boolean(state.device.isMobile));
}

export { reducer, useCheckIfMobileEffect, useGetIsMobile };

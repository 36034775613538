import {
  IsInt,
  IsNumber,
  IsObject,
  IsOptional,
  IsPositive,
  IsString,
} from 'class-validator';
import { DragDropManager } from '@features/canvas/services/DragDropManager';
import { UUID } from 'src/@types/common';
import { UnknownPartial } from 'src/services/database/types';
import {
  IsDraggableType,
  IsFirestoreUUID,
  IsUUIDArray,
} from 'src/services/database/validators/validators';

/**
 * Used for creating pre-defined widget configs, and default canvas content DTOs
 * in session definitions.
 * @internal
 */
export class _CanvasItemConfigBaseDto<TContent = UnknownPartial> {
  /**
   * A static ID that can be used for facilitation logic, e.g. highlighting the element, or scrolling to it.
   * It circumvents the non-determinism of the `id` prop.
   */
  @IsOptional()
  @IsFirestoreUUID()
  readonly semanticId?: UUID;

  /**
   * Describes the content type that informs how to validate the unknown `content` property.
   */
  // TODO introduce DTOs for each widget type
  @IsString()
  readonly contentType: string;

  /**
   * The unique configuration fields of the item.
   */
  @IsObject()
  readonly content: TContent;

  /**
   * The ancestry tree of the item inside the canvas.
   */
  @IsUUIDArray()
  readonly parentNodes: UUID[];

  /**
   * Specifies whether the widget's role in the drag-drop system.
   * This field is **required** if `isMovable` is `true`.
   */
  @IsOptional()
  @IsDraggableType()
  readonly draggableType?: DragDropManager.Drag.DraggableType;

  /**
   * Optional coordinate of the item, if it is positioned.
   * This field is **required** if `isMovable` is `true`.
   */
  @IsOptional()
  @IsNumber()
  readonly x?: number;

  /**
   * Optional coordinate of the item, if it is positioned.
   * This field is **required** if `isMovable` is `true`.
   */
  @IsOptional()
  @IsNumber()
  readonly y?: number;

  /**
   * Optional stack index of the item. Should be a positive integer.
   */
  @IsOptional()
  @IsInt()
  @IsPositive()
  readonly z?: number;

  constructor(dto: _CanvasItemConfigBaseDto<TContent>) {
    this.semanticId = dto.semanticId;
    this.content = dto.content;
    this.contentType = dto.contentType;
    this.parentNodes = dto.parentNodes;
    this.draggableType = dto.draggableType;
    this.x = dto.x;
    this.y = dto.y;
    this.z = dto.z;
  }
}

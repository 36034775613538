import { createSlice } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { flushSessionData } from '../commonActions';

const slice = createSlice({
  name: 'isMarkedComplete',
  initialState: false,
  reducers: {
    markComplete() {
      return true;
    },
  },
  extraReducers: (builder) => builder.addCase(flushSessionData, () => false),
});

const { actions, reducer } = slice;

/**
 * @returns Memoized callback that completes the session regardless of the current
 * user progress. This event can trigger rendering session survey modals, or simply
 * navigating back to the flow screen.
 */
function useMarkSessionComplete() {
  const dispatch = useDispatch();
  return useCallback(
    function markSessionComplete() {
      dispatch(actions.markComplete());
    },
    [dispatch],
  );
}

export { reducer, useMarkSessionComplete };

import {
  IsArray,
  IsEnum,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { UUID } from 'src/@types/common';
import { AiResponse } from 'src/services/ai/aiResponse';
import { AiScoringResponseDto } from 'src/services/ai/aiScoringResponse.dto';
import { IsAiResponse } from 'src/services/ai/validators';
import { FlowInstanceSessionDto } from 'src/services/database/FlowInstances/dto/flowInstanceSession.dto';
import { FlowInstanceAiStatus } from 'src/services/database/FlowInstances/flowInstanceAiStatus';
import { FlowInstanceStatus } from 'src/services/database/FlowInstances/flowInstanceStatus';
import {
  IsFirestoreTimestamp,
  IsFirestoreUUID,
} from 'src/services/database/validators/validators';

/**
 * Contains information for a specific instance of a flow definition.
 * Allowing many instances grants the ability to perform a flow
 * several times while preserving the state of previous iterations.
 */
export class FlowInstanceDto {
  /** Unique identifier for the flow instance. */
  @IsFirestoreUUID()
  readonly id: UUID;

  /** Timestamp of when the flow instance was created. */
  @IsFirestoreTimestamp({ allowNumber: true })
  readonly createdAt: number;

  /**
   * Reference to the canonical flow content stored in the `flowDefinitions`
   * collection, which determines the content of the created instance.
   */
  @IsFirestoreUUID()
  readonly flowDefinitionId: UUID;

  /** Reference to the workspace where the flow instance is created. */
  @IsFirestoreUUID()
  readonly workspaceId: UUID;

  /**
   * Status of the flow instance. This is used to determine if the flow instance
   * is still active or has been completed, and is used to determine the
   * visibility of the instance in the UI.
   */
  @IsEnum(FlowInstanceStatus)
  readonly status: FlowInstanceStatus;

  /**
   * Custom name for the flow instance. Defaults to the flow's title + number of
   * instances on create. If left undefined, the flow's title is used + the
   * creation timestamp - this can happen if it's an old instance.
   * The user can rename the instance at any time.
   * Two instances can have the same name if the user wants to.
   */
  @IsOptional()
  @IsString()
  readonly name?: string;

  /** AI status for the flow instance. */
  @IsEnum(FlowInstanceAiStatus)
  readonly aiStatus: FlowInstanceAiStatus;

  /** Contains the full prompt for the AI. */
  @IsString()
  @IsOptional()
  readonly aiFullPrompt?: string;

  /**
   * Contains the response from the AI.
   */
  @IsOptional()
  @IsAiResponse()
  readonly aiResponse?: AiResponse;

  /**
   * Contains the data for each session of the flow instance, including the
   * AI status and the AI prompt/response.
   */
  @IsArray()
  @ValidateNested({ each: true })
  readonly sessions: FlowInstanceSessionDto[];

  constructor(dto: FlowInstanceDto) {
    this.id = dto.id;
    this.flowDefinitionId = dto.flowDefinitionId;
    this.workspaceId = dto.workspaceId;
    this.createdAt = dto.createdAt;
    this.status = dto.status;
    this.name = dto.name;
    this.sessions =
      dto.sessions?.map((session) => new FlowInstanceSessionDto(session)) || [];
    this.aiStatus = dto.aiStatus;
    this.aiFullPrompt = dto.aiFullPrompt;
    // TODO [KS] Create util for this
    this.aiResponse = dto.aiResponse
      ? typeof dto.aiResponse === 'string'
        ? dto.aiResponse
        : new AiScoringResponseDto(dto.aiResponse)
      : undefined;
  }
}

import { useMemo, useSyncExternalStore } from 'react';
import {
  collection,
  doc,
  documentId,
  DocumentSnapshot,
  FirestoreError,
  getDocs,
  getFirestore,
  orderBy,
  query,
  QueryConstraint,
  where,
} from '@firebase/firestore';
import {
  CollectionQueryArgs,
  FirestoreFilterArg,
} from 'src/services/database/types';
import { UUID } from 'src/@types/common';
import {
  buildSimpleQuery,
  composeQueryConstraints,
  useMemoizedQueryArgs,
} from 'src/services/database/utils';
import { getPathForFlowInstances } from 'src/services/database/paths';
import { useFirestoreQuerySubscription } from 'src/services/database/useFirestoreQuerySubscription';
import { useFirestoreDocSubscription } from 'src/services/database/useFirestoreDocSubscription';
import { FlowInstanceConverter } from 'src/services/database/FlowInstances/converter';
import { FlowInstanceDto } from 'src/services/database/FlowInstances/dto/flowInstance.dto';
import { getLocalized } from 'src/utils/i18n';
import { getFlowDefinitionDoc } from 'src/services/database/FlowDefinitions/FlowDefinitions';

const DEFAULT_QUERY: CollectionQueryArgs<FlowInstanceDto> = {
  sort: {
    key: 'createdAt',
    order: 'desc',
  },
};

/**
 * @param queryArg - Optional sort and filter settings. Defaults to creation time, descending.
 * @returns Firestore collection
 */
export function useFlowInstanceCol(
  queryArg: CollectionQueryArgs<FlowInstanceDto> = DEFAULT_QUERY,
) {
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);
  const filterQuery = useMemo(() => {
    if (!filterBy?.some((filter) => filter?.key === 'workspaceId')) {
      return;
    }

    const constraints: QueryConstraint[] = [];

    if (filterByIds && filterByIds.length > 0) {
      // Firebase throws when filtering for 'in' with an empty array
      constraints.push(where(documentId(), 'in', filterByIds));
    }
    if (filterBy) {
      filterBy
        .filter(
          function noUndefinedFilterCriteria(
            condition,
          ): condition is FirestoreFilterArg<Partial<FlowInstanceDto>> {
            return condition?.value !== undefined;
          },
        )
        .forEach((condition) => {
          constraints.push(where(condition.key, condition.op, condition.value));
        });
    }
    if (sort) {
      constraints.push(orderBy(sort.key, sort.order));
    }

    return query(
      collection(getFirestore(), getPathForFlowInstances()),
      ...constraints,
    );
  }, [filterBy, filterByIds, sort]);

  const store = useFirestoreQuerySubscription(
    FlowInstanceConverter,
    filterQuery,
  );
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(() => ({ result, error: store.error }), [result, store.error]);
}

/**
 * Retrieves a flow instance document from Firestore async.
 */
export function useFlowInstanceDoc(flowInstanceId: UUID): {
  result: DocumentSnapshot<FlowInstanceDto> | undefined;
  error: FirestoreError | undefined;
} {
  const query = useMemo(
    () =>
      flowInstanceId
        ? doc(getFirestore(), getPathForFlowInstances(flowInstanceId))
        : undefined,
    [flowInstanceId],
  );
  const store = useFirestoreDocSubscription(FlowInstanceConverter, query);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(
    () => ({
      result,
      error: store.error,
    }),
    [result, store.error],
  );
}

/**
 * Get the next default name for a flow instance in the format
 * `flowDefinitionTitle N` where N is a number
 * @param flowDefinitionId - The ID of the flow definition
 * @param workspaceId - The ID of the workspace where the flow instance is created
 */
export async function getFlowInstanceGeneratedName(
  flowDefinitionId: UUID,
  workspaceId: UUID,
) {
  const flowDefinitionDoc = await getFlowDefinitionDoc(flowDefinitionId);
  const flowDefinitionTitle = getLocalized(flowDefinitionDoc.data()?.title);

  if (!flowDefinitionTitle) {
    return;
  }
  // Get all instances for this workspace + flow definition ID
  // to determine the default name
  const { docs } = await getDocs(
    query(
      collection(getFirestore(), getPathForFlowInstances()),
      ...composeQueryConstraints(
        buildSimpleQuery<FlowInstanceDto>({
          flowDefinitionId,
          workspaceId,
        }),
      ),
    ),
  );

  const nameReg = new RegExp(`${flowDefinitionTitle} \\d+`, 'i');
  const instancesForThisFlow: number[] =
    docs
      ?.map((doc) => {
        const data = doc.data();
        if (
          !data ||
          data.flowDefinitionId !== flowDefinitionId ||
          !data.name ||
          !nameReg.test(data.name)
        ) {
          return;
        }

        const nameNumber = data.name.match(/\d+$/);
        return nameNumber ? Number.parseInt(nameNumber[0], 10) : 0;
      })
      .filter((num): num is number => {
        return num !== undefined && !Number.isNaN(num) && num > 0;
      }) ?? [];

  const maxInstanceNumber = Math.max(0, ...instancesForThisFlow);
  return `${flowDefinitionTitle} ${maxInstanceNumber + 1}`;
}

import { IsEnum, IsOptional, IsString, MinLength } from 'class-validator';
import { AiResponse } from 'src/services/ai/aiResponse';
import { AiScoringResponseDto } from 'src/services/ai/aiScoringResponse.dto';
import { IsAiResponse } from 'src/services/ai/validators';
import { FlowInstanceAiStatus } from 'src/services/database/FlowInstances/flowInstanceAiStatus';

export class FlowInstanceSessionDto {
  /** Unique identifier for the flow instance session. */
  @MinLength(1)
  readonly id: string;

  /** AI status for the flow instance session. */
  @IsEnum(FlowInstanceAiStatus)
  readonly aiStatus: FlowInstanceAiStatus;

  /** Contains the full prompt for the AI. */
  @IsString()
  @IsOptional()
  readonly aiFullPrompt?: string;

  /**
   * Contains the AI response for the session.
   * This can only be a string for now.
   */
  @IsOptional()
  @IsAiResponse({
    isStringAllowed: true,
    isScoringResponseAllowed: false,
  })
  readonly aiResponse?: AiResponse;

  constructor(dto: FlowInstanceSessionDto) {
    this.id = dto.id;
    this.aiStatus = dto.aiStatus ?? FlowInstanceAiStatus.Initial;
    this.aiFullPrompt = dto.aiFullPrompt;
    // TODO [KS] Create util for this
    this.aiResponse = dto.aiResponse
      ? typeof dto.aiResponse === 'string'
        ? dto.aiResponse
        : new AiScoringResponseDto(dto.aiResponse)
      : undefined;
  }
}

import { useEffect, useState } from 'react';
import { useTypedNavigate } from 'src/app/router';
import { useWorkspaceCol } from 'src/services/database/Workspaces/getters';
import { Path } from 'src/app/router/routes';
import Error500 from 'src/app/pages/Error500';
import { trace } from 'src/services/telemetry';
import { useUserId } from 'src/utils/userContent/hooks';

/**
 * This component redirects the user to the first workspace they have access to.
 * Throws a 500 error if the user has no workspaces.
 */
export function RedirectToFirstWorkspace() {
  const userId = useUserId();
  const navigate = useTypedNavigate();
  const workspaces = useWorkspaceCol();
  const [isServerError, setIsServerError] = useState(false);

  useEffect(() => {
    setIsServerError(false);

    // Still loading
    if (!workspaces.error && !workspaces.result?.docs) {
      return;
    }

    // This shouldn't happen, workspace must be ensured top level
    if (workspaces.result?.docs.length === 0) {
      setIsServerError(true);
      trace(new Error(`No workspaces found for user ${userId}`));
      return;
    }

    // Redirect to the first workspace
    navigate(Path.Workspace, { workspaceId: workspaces.result?.docs?.[0]?.id });
  }, [navigate, workspaces.result?.docs, workspaces.error, userId]);

  // TODO Loading state
  return isServerError ? <Error500 /> : <></>;
}

import { IsBoolean, IsOptional } from 'class-validator';
import { _CanvasItemBaseDto } from '../../_canvasItemBase.dto';
import { UnknownPartial } from '../../types';

/**
 * Contains a piece of content on a canvas that's
 * - usually created by the user (but can be pre-defined in the flow editor),
 * - usually editable by the user (but can be locked),
 * - usually positioned (but currently can be embedded in hand-made canvas layouts).
 */
export class CanvasContentDto<
  TContent = UnknownPartial,
> extends _CanvasItemBaseDto<TContent> {
  /**
   * TODO Not implemented yet. Remove fallback and make required after legacy flows have been migrated.
   * Whether the user can update the item's _content_ (e.g. the cell of a table) in the database.
   * Examples: the content of a table cell; the assigned scores saved in a scoring grid.
   */
  @IsOptional()
  @IsBoolean()
  isContentEditable?: boolean;

  /**
   * TODO Not implemented yet.
   * Whether the user can update the item's coordinates or parent nodes in the database.
   */
  @IsOptional()
  @IsBoolean()
  isMovable?: boolean;

  /**
   * `true` if the user has selected this canvas content as an outcome item of a session.
   *
   * This boolean is flipped simultaneously when the `SessionOutcome` item is created or deleted.
   * Likewise, when this canvas content is deleted, its associated outcome item is also deleted.
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  isSelectedOutcome?: boolean;

  constructor(dto: CanvasContentDto<TContent>) {
    super(dto);
    this.isContentEditable = dto.isContentEditable;
    this.isMovable = dto.isMovable ?? false;
    this.isSelectedOutcome = dto.isSelectedOutcome ?? false;
  }
}

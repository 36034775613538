import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'src/state/useTypedSelector';
import { OnboardingItem, OnboardingStore } from '../types';

const initialState: OnboardingStore = {
  items: {},
  visibleItems: [],
  closedItems: [],
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    publishOnboardingItem: (state, action: PayloadAction<OnboardingItem>) => {
      const { id } = action.payload;
      state.items[id] = action.payload;
    },
    unpublishOnboardingItem: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;
      delete state.items[itemId];
      state.visibleItems = state.visibleItems.filter((id) => id !== itemId);
    },
    flushOnboardingItems: (state) => {
      state.visibleItems = [];
      state.items = {};
    },
    showOnboardingItem: (state, action: PayloadAction<string>) => {
      state.visibleItems.push(action.payload);
    },
    hideOnboardingItem: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;
      state.visibleItems = state.visibleItems.filter((id) => id !== itemId);
      state.closedItems.push(itemId);
    },
    resetOnboarding: (state) => {
      state.visibleItems = [];
      state.items = {};
      state.closedItems = [];
    },
    loadOnboardingData: (
      state,
      action: PayloadAction<{ closedItems: string[] }>,
    ) => {
      state.closedItems = action.payload.closedItems;
    },
  },
});

export default onboardingSlice.reducer;

const {
  publishOnboardingItem,
  unpublishOnboardingItem,
  flushOnboardingItems,
  showOnboardingItem,
  hideOnboardingItem,
  resetOnboarding,
  loadOnboardingData,
} = onboardingSlice.actions;

export const persistedActions = {
  hideOnboardingItem,
  resetOnboarding,
  loadOnboardingData,
} as const;

/**
 * This hook should be used within components that act as the onboarding UI,
 * thereby signaling their availability.
 * An effect hook registers and de-registers the item in Redux on mount and unmount.
 * @param id - Onboarding item ID
 * @param prerequisite - Optional array of other onboarding item IDs that must be closed by the user
 * before allowing this item to be displayed.
 */
export function usePublishOnboardingComponentEffect(
  id: string,
  prerequisite?: string[],
) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(publishOnboardingItem({ id, prerequisite }));
    return () => {
      dispatch(unpublishOnboardingItem(id));
    };
  }, [dispatch, id, prerequisite]);
}

/**
 * @returns Memoized callback for triggering an onboarding item.
 * The item may still **stay hidden** if its `prerequisite` onboarding items haven't been closed yet.
 */
export function useShowOnboardingItem() {
  const dispatch = useDispatch();
  return useCallback(
    (itemId: string) => dispatch(showOnboardingItem(itemId)),
    [dispatch],
  );
}

/**
 * @returns Memoized callback for hiding an onboarding item.
 * This action is persisted in local storage, so the user doesn't have to keep closing the same items.
 */
export function useHideOnboardingItem() {
  const dispatch = useDispatch();
  return useCallback(
    (itemId: string) => dispatch(hideOnboardingItem(itemId)),
    [dispatch],
  );
}

/**
 * Checks the following criteria to determine if an item should be rendered:
 * - it is registered in the `items` store
 * - checks for the `prerequisite` array.
 * - If it's set, checks that none of the preceding items are visible anymore in the `closedItems` store.
 * @param itemId - Onboarding item ID
 * @returns `true` if the item is allowed to render
 */
export function useIsOnboardingItemVisible(itemId: string) {
  return useTypedSelector((state) => {
    const prerequisite = state.onboarding.items[itemId]?.prerequisite ?? [];
    const isAllowed = prerequisite.length === 0;
    const isTriggered = state.onboarding.visibleItems.includes(itemId);
    return isAllowed && isTriggered;
  });
}

/**
 * Erases all the content from the store. Intended for navigation between pages.
 * @returns Memoized callback
 */
export function useFlushOnboardingItems() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(flushOnboardingItems()), [dispatch]);
}

/**
 * It calls {@link useFlushOnboardingItems}, and also erases data stored in local storage.
 * @returns Memoized callback
 */
export function useResetOnboarding() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(resetOnboarding()), [dispatch]);
}

import {
  DocumentData,
  FirestoreDataConverter,
  FirestoreError,
  onSnapshot,
  Query,
  QuerySnapshot,
} from '@firebase/firestore';
import { produce } from 'immer';
import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { trace } from '../telemetry';
import { IFirestoreQuerySubscription, VoidFn } from './types';

// TODO try merging with sibling hooks

/**
 * This is a generic hook that can be used for subscribing to a Firestore query.
 * This pattern is preferred over async/await-ing `getDocs()` calls to ensure good performance.
 *
 * The result of this hook should be passed to a `useSyncExternalStore` hook (see example below).
 *
 * Apart from the Firestore data, the whole query snapshot is returned (including its meta data),
 * so it's easier to manage the documents' lifecycle (e.g. the state of read/write ops).
 *
 * **Not useful for writing data to Firestore.**
 *
 * **Error handling:** If Firestore returns with an error, the listener won't receive any more events,
 * so in this case the parent will probably need to remount this hook.
 * See [Handle listen errors](https://firebase.google.com/docs/firestore/query-data/listen#handle_listen_errors).
 *
 * @example
 * ```ts
 * const TodoDataConverter: FirestoreDataConverter<TodoType, TodoType> = { ... }
 * function useGetTodoCol(id: UUID) {
 *   // Memoization prevents infinite re-renders
 *   const memoizedQuery = useMemo(() =>
 *     query(collection(getFirestore(), 'todoCollectionName', where('status', '==', 'done'))
 *   , [id])
 *   const todoStore = useFirestoreQuerySubscription<TodoType>(TodoDataConverter, memoizedQuery)
 *   return useSyncExternalStore(todoStore.subscribe, todoStore.getSnapshot)
 * }
 * ```
 *
 * @template TFromDb - The data shape coming from the database.
 * @template TToDb - The data shape going to the database. This needs to be set if the data shape contains conversions. Defaults to `FromDb`.
 * @param dataConverter - A Firestore data converter that should
 * - handle conversion to/from Firestore-specific data types
 * - assert the type safety of incoming data.
 * (This could be applied directly to `memoizedCollectionReference`, but this way it won't be forgotten.)
 * @param memoizedCollectionReference - Firestore query.
 * **Be sure it's memoized to avoid infinite re-renders!**
 * @returns A subscription that should be passed to a `useSyncExternalStore` hook
 * @throws {Error} If the collection path is invalid.
 * @see https://react.dev/reference/react/useSyncExternalStore
 * @see https://firebase.google.com/docs/firestore/query-data/listen
 * @see https://firebase.google.com/docs/reference/js/v8/firebase.firestore.FirestoreDataConverter
 */
export function useFirestoreQuerySubscription<
  TFromDb extends DocumentData,
  TToDb extends DocumentData = TFromDb,
>(
  dataConverter: FirestoreDataConverter<TFromDb, TToDb>,
  memoizedCollectionReference: Query | undefined,
): IFirestoreQuerySubscription<TFromDb> {
  const data = useRef<QuerySnapshot<TFromDb> | undefined>();
  const getSnapshot = useCallback(() => data.current, []);

  const [error, setError] = useState<FirestoreError | undefined>();
  useEffect(
    function handleError() {
      if (error) {
        trace({
          category: 'firebase',
          level: 'error',
          message: error.message,
          name: error.name,
          data: data.current,
        });
      }
    },
    [error],
  );

  const subscribe = useCallback(
    (updateListener: VoidFn) => {
      if (!memoizedCollectionReference) {
        return () => {};
      }
      const unsubscribe = onSnapshot(
        memoizedCollectionReference.withConverter(dataConverter),
        (snapshot) => {
          let hasDataChanged = false;
          const currentDocs = data.current?.docs ?? [];

          const nextDocs = produce(currentDocs, (draft) => {
            snapshot.docChanges().forEach((change) => {
              const { doc, type } = change;
              const ix = draft.findIndex((d) => d.id === doc.id);

              switch (type) {
                case 'added': {
                  draft.push(doc);
                  hasDataChanged = true;
                  break;
                }
                case 'modified': {
                  if (ix > -1 && !isEqual(draft[ix]?.data(), doc.data())) {
                    draft[ix] = doc;
                    hasDataChanged = true;
                  }
                  break;
                }
                case 'removed': {
                  if (ix > -1) {
                    draft.splice(ix, 1);
                    hasDataChanged = true;
                  }
                  break;
                }
                // No default case
              }
            });
          });

          if (hasDataChanged || !data.current) {
            data.current = {
              ...snapshot,
              docs: nextDocs,
            } as QuerySnapshot<TFromDb>;
            updateListener();
          }
        },

        (error) => {
          setError(error);
        },
      );

      return () => {
        data.current = undefined;
        unsubscribe();
      };
    },
    [dataConverter, memoizedCollectionReference],
  );

  return useMemo(
    () => ({
      error,
      getSnapshot,
      subscribe,
    }),
    [error, getSnapshot, subscribe],
  );
}

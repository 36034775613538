/**
 * Describes when the survey form is triggered
 * during the session. `start` means 0% progress,
 * `end` means 100% progress.
 */
export enum SurveyTrigger {
  Start = 'start',
  End = 'end',
}

/**
 * Possible value types of session survey form values
 */
export type SurveyAnswerTypes = (string | number)[];

import { createAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const userLogout = createAction('userSession/logout');

export const flushSessionData = createAction('activeSession/flush');

/**
 * Allows flushing the `activeCanvas` and `activeSession` reducers, meaning
 * both the canvas content, and the session meta-data are reset.
 * This prevents the UI from displaying stale data when the user is
 * navigating between various canvas pages.
 * @returns A memoized callback for emptying the `activeCanvas` and `activeSession` reducers
 */
export function useFlushActiveSession() {
  const dispatch = useDispatch();
  return useCallback(
    function flushActiveSession() {
      dispatch(flushSessionData());
    },
    [dispatch],
  );
}

import { serverTimestamp, Timestamp } from '@firebase/firestore';
import { isNumber, isString, ValidationOptions } from 'class-validator';
import { DragDropManager } from '@features/canvas/services/DragDropManager/DragDropManager';
import {
  isLocaleMap,
  isObject,
  isTextObject,
  validateArrayEvery,
} from 'src/utils/typeAssertions/common';
import { createCustomValidator } from './utils';

/**
 * Type guard that returns `true` if `value` is of type `UUID`.
 * Currently, we only support UUIDs as strings, but this validator is there so
 * we can easily change the implementation in the future.
 * The function accepts stand-alone document IDs (e.g. `doc123`)
 * as well as Firestore paths (e.g. `collection/doc123`).
 */
export function isFirestoreUUID(value: unknown) {
  return typeof value === 'string';
}

/**
 * Decorator that checks if a field is a Firestore UUID.
 * The validator accepts stand-alone document IDs (e.g. `doc123`)
 * as well as Firestore paths (e.g. `collection/doc123`).
 * @param options - Validation options.
 * @see {@link isFirestoreUUID}
 */
export function IsFirestoreUUID(options?: ValidationOptions) {
  return createCustomValidator(
    'IsFirestoreUUID',
    isFirestoreUUID,
    (args) => `${args?.property} must be a UUID string`,
    options,
  );
}

/**
 * Type guard that returns `true` if `value` is a singular document ID,
 * and not a document path that contains `/` characters.
 */
export function isFirestoreDocumentId(value: unknown) {
  return isString(value) && !value.includes('/');
}

/**
 * Decorator that checks if `value` is a singular document ID,
 * and not a document path that contains `/` characters.
 */
export function IsFirestoreDocumentId(options?: ValidationOptions) {
  return createCustomValidator(
    'IsFirestoreDocumentId',
    isFirestoreDocumentId,
    (args) =>
      `${args?.property} cannot be a path, so it must not contain / characters`,
    options,
  );
}

/**
 * Type guard that returns `true` if `value` is
 * - `null` (as returned by `serverTimestamp()`)
 * - sharing the same prototype as the sentinel value returned by `serverTimestamp()`
 * - instance of {@link Timestamp}.
 *
 * Date-time is stored in a special format by Firestore, so we need to
 * convert to/from that format.
 *
 * But because `serverTimestamp()` is used for submitting timestamps, by default Firestore's getters
 * fall back to `null` until they receive the final calculated field value from the database.
 * @see https://firebase.google.com/docs/reference/js/v8/firebase.firestore.SnapshotOptions#optional-servertimestamps
 * @see https://stackoverflow.com/a/65627037
 */
export function isFirestoreTimestamp(value: unknown, allowNumber = false) {
  if (value === null) {
    return true;
  } else if (allowNumber && typeof value === 'number') {
    return true;
  }

  const isServerTimestampReturnValue =
    isObject(value) &&
    isObject(serverTimestamp()) &&
    value.constructor.prototype === serverTimestamp().constructor.prototype;

  return value instanceof Timestamp || isServerTimestampReturnValue;
}

interface IIsFirestoreTimestampOptions {
  allowNumber?: boolean;
}

/**
 * Decorator that checks if a field is a Firestore Timestamp.
 * @param timestampOptions - Extra options for the validator.
 * @param options - Validation options.
 * @see {@link isFirestoreTimestamp}
 */
export function IsFirestoreTimestamp(
  timestampOptions: IIsFirestoreTimestampOptions = {},
  options?: ValidationOptions,
) {
  return createCustomValidator(
    'IsFirestoreTimestamp',
    (value) =>
      isFirestoreTimestamp(value, timestampOptions?.allowNumber ?? false),
    (args) =>
      `${args?.property} must be null | number | ReturnType<serverTimestamp>`,
    options,
  );
}

/**
 * Decorator that checks if a field is a {@link LocaleMap} -> {@link TextObject}.
 * @param options - Validation options.
 * @see {@link isLocaleMap}
 * @see {@link isTextObject}
 */
export function IsLocaleText(options?: ValidationOptions) {
  return createCustomValidator(
    'IsLocaleText',
    (value) => isLocaleMap(value, isTextObject),
    (args) => `${args?.property} must be a LocaleMap<TextObject>`,
    options,
  );
}

function isStringOrNumber(value: unknown) {
  return isNumber(value) || isString(value);
}

/**
 * Decorator for validating whether a value is a string or number.
 */
export function IsStringOrNumber(options?: ValidationOptions) {
  return createCustomValidator(
    'IsStringOrNumber',
    isStringOrNumber,
    (args) => `${args?.property} must be a string or number`,
    options,
  );
}

function isInRange(value: number, min: number, max: number): boolean {
  if (min > max) {
    [min, max] = [max, min];
  }
  return typeof value === 'number' && value >= min && value <= max;
}

/**
 * Decorator for validating that the value is a `number` and
 * falls between the min and max values mathematically.
 */
export function IsInRange(
  min: number,
  max: number,
  validationOptions?: ValidationOptions,
) {
  return createCustomValidator(
    'isInRange',
    (value) => typeof value === 'number' && isInRange(value, min, max),
    (args) => {
      if (!args?.constraints || !Array.isArray(args?.constraints)) {
        throw new Error('[minValue, maxValue] constraints must be set');
      }
      const [min, max] = args.constraints;
      return `$property must be between ${min} and ${max}`;
    },
    validationOptions,
    [min, max],
  );
}

/**
 * Type guard that returns `true` if `value` is a valid user ID.
 * Currently, we only support user IDs as strings, but this validator is there
 * so we can easily change the implementation in the future.
 * @param value
 */
function isUserId(value: unknown) {
  return isString(value);
}

/**
 * Decorator that checks if a field is a valid user ID.
 * @param options - Validation options.
 */
export function IsUserId(options?: ValidationOptions) {
  return createCustomValidator(
    'IsUserId',
    isUserId,
    (args) => `${args?.property} must be a user ID`,
    options,
  );
}

/**
 * Decorator that checks if a field contains valid canvas item parent nodes.
 * @param options - Validation options.
 */
export function IsUUIDArray(options?: ValidationOptions) {
  return createCustomValidator(
    'IsParentNodesArray',
    validateArrayEvery(isFirestoreUUID),
    (args) => `${args?.property} must be an array of UUIDs`,
    options,
  );
}

/**
 * Decorator that checks if a field is a valid canvas drag-drop type.
 * @param options - Validation options.
 */
export function IsDraggableType(options?: ValidationOptions) {
  return createCustomValidator(
    'IsDraggableType',
    (val) => {
      const arr: DragDropManager.Drag.DraggableType[] = ['box', 'dropZone'];
      return arr.includes(val as DragDropManager.Drag.DraggableType);
    },
    (args) => `${args?.property} is not a valid draggable type: ${args?.value}`,
    options,
  );
}

import { createContext } from 'react';
import { UUID } from 'src/@types/common';
import { IDynamicFlowAdapter } from 'src/@types/flow';

/**
 * ! Context must be in a separate file than context provider, otherwise HMR breaks!
 * @see https://github.com/vitejs/vite/issues/3301#issuecomment-1192661323
 */

export interface IFlowContext {
  /** ID of the currently loaded flow */
  flowDefinitionId: UUID | null;
  /** URL path param used for looking up the flow's UUID */
  flowPath: string | null;
  /** `true` if a flow adapter was loaded, and content from the database was also loaded */
  isFlowLoaded: boolean;
  /** The currently loaded flow adapter */
  flowAdapter: IDynamicFlowAdapter | null;
  /** Used for storing a dynamically imported flow adapter */
  setFlowAdapter: (arg: IDynamicFlowAdapter) => void;
}

export const FlowContext = createContext<IFlowContext | undefined>(undefined);

import { IsBoolean, IsOptional, IsString } from 'class-validator';
import { _CanvasItemBaseDto } from '../../_canvasItemBase.dto';
import { UnknownPartial } from '../../types';

/**
 * Contains a stateful canvas widget that has been instantiated on a canvas.
 * Such widgets can persist their unique state in the database, as if they were some editable content.
 */
export class CanvasWidgetDto<
  TContent = UnknownPartial,
> extends _CanvasItemBaseDto<TContent> {
  /**
   * AI evaluation will skip this node, and all of its content (including child nodes).
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  readonly aiIsIgnoredOnEvaluate?: boolean;

  /**
   * Allows AI evaluation to use a custom prompt (instead of the prompt inherited from parent nodes)
   * for this node from the `aiCustomPrompt` field.
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  readonly aiIsCustomPromptEnabled?: boolean;

  /**
   * Optional custom prompt for AI evaluation (instead of the prompt inherited from parent nodes).
   * This prompt is only considered if `aiCustomPromptEnabled` is `true`.
   * @default false
   */
  @IsOptional()
  @IsString()
  readonly aiCustomPrompt?: string;

  /**
   * TODO Not implemented yet. Make required after implementation
   * Whether the user can update the item's _settings_ (e.g. the cell of a table) in the database.
   * Examples: rewriting the labels of a scoring grid.
   */
  @IsOptional()
  @IsBoolean()
  isConfigEditable?: boolean;

  /**
   * TODO Not implemented yet. Make required after implementation
   * Whether the user can update the item's _content_ (e.g. the cell of a table) in the database.
   * Examples: the content of a table cell; the assigned scores saved in a scoring grid.
   */
  @IsOptional()
  @IsBoolean()
  isContentEditable?: boolean;

  /**
   * TODO Not implemented yet. Make required after implementation
   * Whether the user can update the item's coordinates or parent nodes in the database.
   */
  @IsOptional()
  @IsBoolean()
  isMovable?: boolean;

  constructor(dto: CanvasWidgetDto<TContent>) {
    super(dto);
    this.aiCustomPrompt = dto.aiCustomPrompt;
    this.aiIsCustomPromptEnabled = dto.aiIsCustomPromptEnabled ?? false;
    this.aiIsIgnoredOnEvaluate = dto.aiIsIgnoredOnEvaluate ?? false;
    this.isConfigEditable = dto.isConfigEditable;
    this.isContentEditable = dto.isContentEditable;
    this.isMovable = dto.isMovable;
  }
}

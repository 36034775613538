import { useMemo } from 'react';
import { FlowDefinitionPaths } from 'src/flows/flowDefinitionPaths';
import { useFlowInstanceDoc } from 'src/services/database/FlowInstances/getters';
import { useActiveParam } from 'src/state/active/hooks';

/**
 * Returns the active workspace ID.
 */
export function useWorkspaceId() {
  return useActiveParam('workspaceId') ?? '';
}

/**
 * Retrieves the active flow instance ID.
 */
export function useFlowInstanceId() {
  return useActiveParam('flowInstanceId') ?? '';
}

/**
 * Retrieves the active flow definition ID.
 *
 * The definition ID should be set as a URL path param.
 * If it isn't available, then the hook tries retrieving the active flow instance,
 * and use the definition ID stored in that document.
 */
export function useFlowDefinitionId() {
  const pathParam = useActiveParam('flowDefinitionId');
  const fallbackDefinitionId = useFlowInstanceDoc(
    useFlowInstanceId(),
  ).result?.data()?.flowDefinitionId;
  return pathParam ?? fallbackDefinitionId ?? '';
}

/**
 * Builds the flow definition path used in import file paths.
 */
export function useFlowDefinitionPath() {
  const flowDefinitionId = useFlowDefinitionId();
  return useMemo(
    () => FlowDefinitionPaths[flowDefinitionId] ?? flowDefinitionId,
    [flowDefinitionId],
  );
}

/**
 * @returns ID of the current session's definition
 */
export function useSessionId() {
  return useActiveParam('sessionId') ?? '';
}

import { IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { DragDropManager } from '@features/canvas/services/DragDropManager';
import { UUID } from 'src/@types/common';
import { UnknownPartial } from './types';
import {
  IsDraggableType,
  IsFirestoreUUID,
  IsUUIDArray,
} from './validators/validators';

/**
 * Intended to be used by stateful canvas widgets and canvas contents (i.e. user-created items).
 * @internal
 */
export class _CanvasItemBaseDto<TContent = UnknownPartial> {
  /** The item's unique ID (it should match the Firestore document ID). */
  @IsFirestoreUUID()
  readonly id: UUID;

  /**
   * A static ID that can be used for facilitation logic, e.g. highlighting the element, or scrolling to it.
   * It circumvents the non-determinism of the `id` prop.
   */
  @IsOptional()
  @IsFirestoreUUID()
  readonly semanticId?: UUID;

  /** ID of the flow instance where the canvas content was created. */
  @IsFirestoreUUID()
  readonly flowInstanceId: UUID;

  /** ID of the canvas session where the canvas content was created. */
  @IsFirestoreUUID()
  readonly sessionId: UUID;

  /**
   * Custom item content. Its format will depend on the component's use case.
   * E.g. a canvas note with custom background colors will have different props than a simple embedded date-picker.
   */
  @IsObject()
  content: TContent;

  /**
   * Describes the type of data stored in the `content` prop.
   * This can be used e.g. to selectively run a type assertion function on the content value.
   */
  @IsString()
  readonly contentType: string;

  /**
   * Legacy prop kept for compatibility.
   * @deprecated Use `contentType`.
   */
  @IsString()
  readonly type: string;

  /**
   * UTC time when the item was created.
   */
  @IsNumber()
  readonly createdAt: number;

  /** ID of user who created this content. */
  @IsFirestoreUUID()
  readonly createdBy: UUID;

  @IsOptional()
  @IsDraggableType()
  /**
   * This type determines whether the rendered item is draggable,
   * or if it acts as a drop zone that accepts other draggable or droppable items.
   */
  readonly draggableType?: DragDropManager.Drag.DraggableType;

  /**
   * UTC time when the item was last edited by anyone.
   */
  @IsNumber()
  editedAt: number;

  /** Array of user IDs who have edited this content after its creation. (also includes the creator's user ID) */
  @IsUUIDArray()
  editedBy: UUID[];

  /**
   * UTC time when any user last moved the item, if the content can be positioned.
   * */
  @IsOptional()
  @IsNumber()
  lastMovedAt?: number;

  /**
   * Ancestry tree of the draggable item that contains the IDs of all its parents,
   * starting from its immediate parent up to the root drop zone.
   *
   * Its main use case is so draggable children can hide the original component
   * not only when they are dragged, but also when their parent drop zone is dragged.
   */
  @IsOptional()
  @IsUUIDArray()
  parentNodes?: UUID[];

  /** Item's X coordinate in `px`, if the content can be positioned. */
  @IsOptional()
  @IsNumber()
  x?: number;

  /** Item's Y coordinate in `px`, if the content can be positioned. */
  @IsOptional()
  @IsNumber()
  y?: number;

  constructor(dto: _CanvasItemBaseDto<TContent>) {
    this.content = dto.content;
    this.contentType = dto.contentType;
    this.type = dto.contentType;
    this.createdAt = dto.createdAt;
    this.createdBy = dto.createdBy;
    this.draggableType = dto.draggableType;
    this.editedAt = dto.editedAt;
    this.editedBy = dto.editedBy;
    this.flowInstanceId = dto.flowInstanceId;
    this.id = dto.id;
    this.lastMovedAt = dto.lastMovedAt;
    this.parentNodes = dto.parentNodes;
    this.semanticId = dto.semanticId;
    this.sessionId = dto.sessionId;
    this.x = dto.x;
    this.y = dto.y;
  }
}

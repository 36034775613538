import { IsEnum, IsOptional, IsString, ValidateNested } from 'class-validator';
import { UUID } from 'src/@types/common';
import { IsFirestoreUUID } from 'src/services/database/validators/validators';
import { SurveyTrigger } from '../../SurveyConfigs.types';
import {
  SurveyCheckboxInputDto,
  SurveyInputType,
  SurveyRadioInputDto,
  SurveyReflectionInputDto,
  SurveyTextInputDto,
} from './surveyInput.dto';
import { SurveyConfigPreviousDataSourceDto } from './surveyPreviousDataSource.dto';

export class SurveyConfigDto {
  /** Unique identifier of the survey config */
  @IsFirestoreUUID()
  readonly id: UUID;

  /**
   * Describes when the survey form is triggered
   * during the session. `start` means at 0% progress,
   * `end` means at 100% progress.
   */
  @IsEnum(SurveyTrigger)
  readonly trigger: SurveyTrigger;

  /** Typically the question displayed to the user in the form. */
  @IsString()
  readonly title: string;

  /**
   * Controls what kind of input is rendered for the user.
   * Only one type per form is supported.
   */
  @ValidateNested()
  readonly input:
    | SurveyTextInputDto
    | SurveyCheckboxInputDto
    | SurveyRadioInputDto
    | SurveyReflectionInputDto;

  /** Optional remarks intended for the modal footer. */
  @IsOptional()
  @IsString()
  readonly footerText?: string;

  /**
   * Defines whether the modal should render the user's input from a preceding
   * survey form. This can be used e.g. to ask the user if they succeeded
   * in their goals.
   */
  @IsOptional()
  @ValidateNested()
  readonly previousDataSource?: SurveyConfigPreviousDataSourceDto;

  constructor(dto: SurveyConfigDto) {
    this.id = dto.id;
    this.trigger = dto.trigger;
    this.title = dto.title;
    this.input = convertToInputDto(dto.input);
    this.footerText = dto.footerText;
    if (dto.previousDataSource) {
      this.previousDataSource = new SurveyConfigPreviousDataSourceDto(
        dto.previousDataSource,
      );
    }
  }
}

function convertToInputDto(input: SurveyConfigDto['input']) {
  switch (input.type) {
    case SurveyInputType.Checkbox: {
      return new SurveyCheckboxInputDto(input);
    }
    case SurveyInputType.Radio: {
      return new SurveyRadioInputDto(input);
    }
    case SurveyInputType.Reflection: {
      return new SurveyReflectionInputDto(input);
    }
    case SurveyInputType.Text: {
      return new SurveyTextInputDto(input);
    }
    default: {
      throw new Error(
        `Unknown survey input type: ${(input as SurveyConfigDto['input'])
          ?.type}`,
      );
    }
  }
}

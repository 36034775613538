/**
 * Used as a local storage key for persisting some Redux data.
 */
export const ONBOARDING_STORAGE_KEY = 'onboarding_closedItems';

/**
 * Defines a "handle" for triggering components
 * serving as onboarding messages.
 */
export interface OnboardingItem {
  /**
   * ID associated to a hint component, so other unrelated components
   * can trigger showing/hiding it.
   */
  id: string;
  /**
   * Optional array of other onboarding item IDs that must be closed by the user
   * before allowing this item to be displayed.
   */
  prerequisite?: string[];
}

/**
 * Redux slice used for tracking the existing onboarding items.
 */
export type OnboardingStore = {
  items: Record<string, OnboardingItem>;
  visibleItems: string[];
  closedItems: string[];
};

export interface AnalyticsEvent {
  userId: string;
  workspaceId: string;
  onboardingItemId: string;
  /** When the event happened (not when it's being logged). */
  timestamp: number;
}

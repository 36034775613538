import { CSSProperties } from 'react';
import { CSSLengthUnit } from 'src/@types/common';
import { ball, circle, circleBallWrapper } from './CircleBall.css';

interface ICircleBallProps {
  color: CSSProperties['color'];
  size: CSSLengthUnit;
}

export function CircleBall({ color, size }: ICircleBallProps) {
  return (
    <div
      className={circleBallWrapper}
      style={{
        width: size,
        height: size,
        color,
      }}
    >
      <div className={circle} />
      <div className={ball} />
    </div>
  );
}

import {
  QuerySnapshot,
  collection,
  doc,
  getFirestore,
  query,
} from '@firebase/firestore';
import { useMemo, useRef, useSyncExternalStore } from 'react';
import { UUID } from 'src/@types/common';
import {
  Enrollment,
  EnrollmentConverter,
  EnrollmentToDb,
} from 'src/services/database/Enrollments/utils';
import { getPathForEnrollments } from 'src/services/database/paths';
import { CollectionQueryArgs } from 'src/services/database/types';
import { useFirestoreDocSubscription } from 'src/services/database/useFirestoreDocSubscription';
import { useFirestoreQuerySubscription } from 'src/services/database/useFirestoreQuerySubscription';
import {
  buildSimpleQuery,
  composeQueryConstraints,
  useMemoizedQueryArgs,
} from 'src/services/database/utils';
import { Resource } from 'src/utils/resource';
import {
  useFlowInstanceId,
  useSessionId,
  useWorkspaceId,
} from 'src/utils/resource.hooks';
import { useUserId } from 'src/utils/userContent/hooks';

/**
 * Subscribes to Firestore and returns a single {@link Enrollment} document.
 * @param enrollmentId - ID of the enrollment document
 */
export function useEnrollmentDoc(enrollmentId: UUID) {
  const instanceQuery = useMemo(
    () =>
      enrollmentId
        ? doc(getFirestore(), getPathForEnrollments(enrollmentId))
        : undefined,
    [enrollmentId],
  );
  const converter = useRef(EnrollmentConverter);
  const store = useFirestoreDocSubscription<Enrollment, EnrollmentToDb>(
    converter.current,
    instanceQuery,
  );
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(
    () => ({
      result,
      error: store.error,
    }),
    [result, store.error],
  );
}

/**
 * Subscribes to Firestore and returns a collection of {@link Enrollment} documents.
 * @param queryArg - Query argument that specifies which documents are returned.
 * @returns A Firestore collection reference.
 */
export function useEnrollmentCol(
  queryArg: CollectionQueryArgs<Enrollment>,
): Resource<QuerySnapshot<Enrollment>> {
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);
  const queryMemo = useMemo(
    () =>
      query(
        collection(getFirestore(), getPathForEnrollments()),
        ...composeQueryConstraints({ filterBy, filterByIds, sort }),
      ),
    [filterBy, filterByIds, sort],
  );

  const store = useFirestoreQuerySubscription<Enrollment, EnrollmentToDb>(
    EnrollmentConverter,
    queryMemo,
  );
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(
    () => ({
      result,
      error: store.error,
    }),
    [result, store.error],
  );
}

/**
 * Fetches the enrollment document for the current user, workspace, and flow instance.
 */
export function useEnrollment() {
  const creatorId = useUserId();
  const workspaceId = useWorkspaceId();
  const flowInstanceId = useFlowInstanceId();
  const query = useMemo(
    () =>
      buildSimpleQuery({
        creatorId,
        workspaceId,
        flowInstanceId,
      }),
    [creatorId, workspaceId, flowInstanceId],
  );
  const enrollmentsFound = useEnrollmentCol(query);

  return enrollmentsFound.result?.docs?.[0];
}

/**
 * Fetches the enrollment ID for the current user, workspace, and flow instance.
 */
export function useEnrollmentId() {
  const enrollment = useEnrollment();
  return enrollment?.id;
}

/**
 * Fetches the status of the enrollment with the given ID.
 * @param id - ID of the enrollment document
 */
export function useEnrollmentStatus(id: UUID) {
  const enrollment = useEnrollmentDoc(id);
  return enrollment.result?.data()?.status;
}

/**
 * Fetches the session progress for the current user, workspace, flow instance, and session definition.
 * @param sessionIdOverride - Optional escape hatch for the flow instance page,
 * where the URL doesn't contain a session definition ID.
 */
export function useEnrollmentProgressForSession(sessionIdOverride?: UUID) {
  const sessionId = useSessionId();
  const targetSessionId = sessionIdOverride ?? sessionId;
  const enrollment = useEnrollment();

  return enrollment
    ?.data()
    ?.sessionProgress?.find((x) => x.sessionId === targetSessionId);
}

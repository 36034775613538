import { combineReducers } from '@reduxjs/toolkit';
import { reducer as canSelectOutcome } from './canSelectOutcome';
import { reducer as hiddenOutcomeIds } from './hiddenOutcomeIds';
import { reducer as isMarkedComplete } from './isMarkedComplete';
import { reducer as layout } from './layout';
import { reducer as sidebar } from './sidebar';

export const activeSession = combineReducers({
  canSelectOutcome,
  hiddenOutcomeIds,
  isMarkedComplete,
  layout,
  sidebar,
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PlayerView } from 'src/@types/video';
import { flushSessionData } from '../../commonActions';
import { useTypedSelector } from '../../useTypedSelector';
import { footerLayoutActions } from './footer';

// TODO export a single class-like API like the in footer.ts?

const initialState: {
  isVisible: boolean;
  view: PlayerView;
} = {
  view: 'avatarBig',
  isVisible: true,
};

const slice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setView(state, action: PayloadAction<PlayerView>) {
      state.view = action.payload;
    },
    toggle(state) {
      state.isVisible = !state.isVisible;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(flushSessionData, () => initialState)
      // Triggers changing video DOM parent when footer changes
      .addCase(footerLayoutActions.setLayout, (state, footerAction) => {
        const { view: nextFooterView } = footerAction.payload;
        const isVideoBoundToFooter =
          state.view !== 'medium' && state.view !== 'full';

        switch (nextFooterView) {
          case 'compact': {
            state.view = 'avatarSmall';
            break;
          }
          case 'expanded': {
            if (isVideoBoundToFooter) {
              state.view = 'avatarBig';
            }
            break;
          }
          case 'mobileLarge':
          case 'mobileSmall': {
            state.view = 'avatarSmallMobile';
            break;
          }
          // No default
        }
      });
  },
});

const { actions, reducer } = slice;

function useSetVideoLayout() {
  const dispatch = useDispatch();
  return useCallback(
    function setVideoView(view: PlayerView) {
      dispatch(actions.setView(view));
    },
    [dispatch],
  );
}

/**
 * @returns The visibility state of the video avatar
 */
function useGetVideoUiState() {
  return useTypedSelector((state) => state.activeSession.layout.video);
}

export { reducer, useGetVideoUiState, useSetVideoLayout as useSetVideoView };

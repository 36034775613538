import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import debounce from 'lodash.debounce';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CanvasRect } from 'src/@types/dnd';
import { flushSessionData } from '../commonActions';
import { IRootState } from '../types';
import { useTypedSelector } from '../useTypedSelector';

const initialState: CanvasRect = {
  width: 0,
  height: 0,
  offsetLeft: 0,
  offsetTop: 0,
};

/**
 * Generates a Redux slice that stores an element's size and offset.
 * This data is used both for the canvas element and the viewport itself,
 * so dragged element positions are calculated correctly.
 * @param sliceName - The slice's name. Used to namespace the generated action types.
 * @returns The generated slice
 */
const createMeasurerSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      set(_state, action: PayloadAction<CanvasRect>) {
        return action.payload;
      },
    },
    extraReducers: (builder) =>
      builder.addCase(flushSessionData, () => initialState),
  });

/**
 * Facilitates the measurement of a DOM element or the viewport.
 * Offset values are normalized, so no negative values are stored.
 * @returns A tuple of the measurement ref, and the size and offset values stored in an object
 */
function useElementMeasurement(interval = 100) {
  const [result, setResult] = useState<CanvasRect>({
    offsetLeft: 0,
    offsetTop: 0,
    width: 0,
    height: 0,
  });
  const measureRef = useRef<HTMLDivElement>(null);
  const handleResize = useMemo(
    () =>
      debounce(
        () => {
          const element = measureRef.current;
          if (!element) return;
          const { left, top, width, height } = element.getBoundingClientRect();
          // Left and top can sometimes be negative on slow devices,
          // which doesn't make sense for the canvases
          const leftNormalized = Math.max(left ?? 0, 0);
          const topNormalized = Math.max(top ?? 0, 0);
          setResult({
            offsetLeft: leftNormalized,
            offsetTop: topNormalized,
            width,
            height,
          });
        },
        interval,
        { trailing: true },
      ),
    [interval],
  );

  useLayoutEffect(() => {
    const element = measureRef.current;
    if (!element) return;

    handleResize();
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
      handleResize.cancel();
    };
  }, [handleResize, interval]);

  const sidebarState = useTypedSelector(
    (state) => state.activeSession.sidebar.animationState,
  );
  useEffect(
    function forceRecalculateOnSidebarChange() {
      if (sidebarState === 'hide' || sidebarState === 'show') handleResize();
    },
    [handleResize, sidebarState],
  );

  return useMemo(() => [measureRef, result] as const, [result, measureRef]);
}
/**
 * @param rootSelector - Selector that returns the measured element's size data
 * @returns An object containing the element's width and height
 */
function useGetMeasuredElementSize(
  rootSelector: (state: IRootState) => CanvasRect,
) {
  const width = useTypedSelector((state) => rootSelector(state).width);
  const height = useTypedSelector((state) => rootSelector(state).height);
  return useMemo(() => ({ width, height }), [width, height]);
}

/**
 *
 * @param rootSelector - Selector that returns the measured element's size data
 * @returns  An object containing the element's top and left offset
 */
function useGetMeasuredElementOffset(
  rootSelector: (state: IRootState) => CanvasRect,
) {
  const offsetLeft = useTypedSelector(
    (state) => rootSelector(state).offsetLeft,
  );
  const offsetTop = useTypedSelector((state) => rootSelector(state).offsetTop);
  return useMemo(() => ({ offsetLeft, offsetTop }), [offsetLeft, offsetTop]);
}

export {
  createMeasurerSlice,
  useElementMeasurement,
  useGetMeasuredElementOffset,
  useGetMeasuredElementSize,
};

import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Offset, Size } from 'src/@types/dnd';
import {
  createMeasurerSlice,
  useElementMeasurement,
  useGetMeasuredElementOffset,
  useGetMeasuredElementSize,
} from './_measurerFactory';

const { actions, reducer } = createMeasurerSlice('viewportRect');

/**
 * Measures a DOM element's width, height and offset, and publishes
 * these values to Redux. This hook should be used for measuring
 * the scrollable viewport's rect.
 * _(Note that the viewport's scroll position is not stored here.)_
 *
 * In some unique cases this information can be used for setting
 * the dimensions of canvas elements.
 * @param debounce - Interval between measurements in milliseconds
 * @returns Ref that should be attached to the measured DOM element
 */
function usePublishViewportRect() {
  const [measureRef, rect] = useElementMeasurement();
  const dispatch = useDispatch();
  useLayoutEffect(
    function publish() {
      dispatch(actions.set(rect));
    },
    [dispatch, rect],
  );
  return measureRef;
}

/**
 * Provides the canonical width and height of the scrollable viewport.
 * @returns The viewport size
 */
function useGetViewportSize(): Size {
  return useGetMeasuredElementSize((state) => state.activeCanvas.viewportRect);
}

/**
 * Provides the canonical offset values of the scrollable viewport.
 * @returns The viewport offset
 */
function useGetViewportOffset(): Offset {
  return useGetMeasuredElementOffset(
    (state) => state.activeCanvas.viewportRect,
  );
}

export {
  reducer,
  usePublishViewportRect,
  useGetViewportOffset,
  useGetViewportSize,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { XYCoord } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { flushSessionData } from '../commonActions';
import { useTypedSelector } from '../useTypedSelector';

const initialState: {
  isAllowed: boolean;
  position: XYCoord;
} = {
  isAllowed: true,
  position: { x: 0, y: 0 },
};
const slice = createSlice({
  name: 'viewportScroll',
  initialState,
  reducers: {
    savePosition(state, action: PayloadAction<XYCoord>) {
      state.position = action.payload;
    },
    setAllowScroll(state, action: PayloadAction<boolean>) {
      state.isAllowed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(flushSessionData, (state) => {
      state.position = initialState.position;
    });
  },
});

export const { reducer } = slice;
const { actions } = slice;

/**
 * Attaches scroll event listeners to an element (throttled to 100ms),
 * and the scroll positions are published in Redux.
 * @returns A ref to be attached to the scrollable viewport's DOM element.
 */
export function useObserveScrollPosition() {
  const elemRef = useRef<HTMLElement>();
  const dispatch = useDispatch();
  const listener = useCallback(
    function setScrollPosition() {
      requestAnimationFrame(() => {
        if (!elemRef.current) return;
        const { scrollLeft, scrollTop } = elemRef.current;
        dispatch(actions.savePosition({ x: scrollLeft, y: scrollTop }));
      });
    },
    [dispatch],
  );
  useLayoutEffect(() => {
    const ref = elemRef.current;
    if (!ref) return;
    ref.addEventListener('scroll', listener);
    return () => ref?.removeEventListener('scroll', listener);
  }, [listener]);
  return elemRef;
}

/**
 * Returns the published scroll position of the canvas viewport element.
 */
export function useGetScrollPosition() {
  return useTypedSelector(
    (state) => state.activeCanvas.viewportScroll.position,
    (oldPos, newPos) => oldPos.x === newPos.x && oldPos.y === newPos.y,
  );
}

/**
 * @returns Memoized callback that enables/disables scrolling the scrollable viewport DOM element.
 */
export function useAllowScrolling() {
  const dispatch = useDispatch();
  return useCallback(
    function allowScrolling(isAllowed: boolean) {
      dispatch(actions.setAllowScroll(isAllowed));
    },
    [dispatch],
  );
}

/**
 * @returns `true` if the scrollable viewport is allowed to scroll
 */
export function useIsScrollingAllowed() {
  return useTypedSelector(
    (state) => state.activeCanvas.viewportScroll.isAllowed,
  );
}

import { IsOptional, IsString } from 'class-validator';
import { UUID } from 'src/@types/common';
import { IsFirestoreUUID } from 'src/services/database/validators/validators';

/**
 * Defines whether the modal should render the user's input from a preceding
 * survey form. This can be used e.g. to ask the user if they succeeded
 * in their goals.
 */
export class SurveyConfigPreviousDataSourceDto {
  /**
   * ID of session whose data contain the referred survey.
   */
  @IsFirestoreUUID()
  readonly sessionId: UUID;

  /**
   * Survey ID whose data is referred.
   *
   * The previous survey's data contains information about its input type,
   * which should determine how to render the user's answers (e.g. checkboxes vs radio buttons).
   */
  @IsFirestoreUUID()
  readonly surveyId: UUID;

  /**
   * Title that should be rendered for the user along with their survey input.
   */
  @IsOptional()
  @IsString()
  readonly title?: string;

  constructor(dto: SurveyConfigPreviousDataSourceDto) {
    this.sessionId = dto.sessionId;
    this.surveyId = dto.surveyId;
    this.title = dto.title;
  }
}

import { combineReducers } from '@reduxjs/toolkit';
import { reducer as canvasRect } from './canvasRect';
import { reducer as editedItem } from './editedItem';
import { reducer as idMarkedForDelete } from './idMarkedForDelete';
import { reducer as sizes } from './sizes';
import { reducer as viewportRect } from './viewportRect';
import { reducer as viewportScroll } from './viewportScroll';

export const activeCanvas = combineReducers({
  canvasRect,
  editedItem,
  idMarkedForDelete,
  sizes,
  viewportRect,
  viewportScroll,
});

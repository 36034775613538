import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { flushSessionData } from '../commonActions';
import { useTypedSelector } from '../useTypedSelector';

const slice = createSlice({
  name: 'canSelectOutcome',
  initialState: false,
  reducers: {
    setCanSelect(_state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(flushSessionData, () => false);
  },
});

const { actions, reducer } = slice;

/**
 * @returns A memoized callback for enabling/disabling session outcome selection
 */
function useSetCanSelectItems() {
  const dispatch = useDispatch();
  return useCallback(
    function setCanSelectItems(canSelectItems: boolean) {
      dispatch(actions.setCanSelect(canSelectItems));
    },
    [dispatch],
  );
}

/**
 * @returns `true` if the user can select canvas items as the session outcome
 */
function useGetCanSelectItems() {
  return useTypedSelector((state) => state.activeSession.canSelectOutcome);
}

export { reducer, useGetCanSelectItems, useSetCanSelectItems };

import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useWorkspaceDoc } from 'src/services/database/Workspaces/getters';
import { useWorkspaceId } from 'src/utils/resource.hooks';
import { trace } from 'src/services/telemetry';
import Error404 from 'src/app/pages/Error404';
import Error500 from 'src/app/pages/Error500';
import { useUserId } from 'src/utils/userContent/hooks';

/**
 * Redirects the user to the 404 page if the workspace is not found or the user is not a member of the workspace.
 * Throws a 500 error if there is an error fetching the workspace.
 * @param children - The children to render if the user has access to the workspace.
 * @returns The children if the user has access to the workspace, otherwise null.
 */
export function WorkspaceAccessRouteGuard({ children }: PropsWithChildren) {
  const userId = useUserId();
  const workspaceId = useWorkspaceId();
  const workspaceDoc = useWorkspaceDoc(workspaceId);
  const canRender = useMemo(() => {
    return !!workspaceDoc.result?.data()?.members[userId];
  }, [workspaceDoc.result, userId]);

  // Using effect to trace errors
  useEffect(() => {
    if (workspaceDoc.error) {
      trace(workspaceDoc.error);
    }
  }, [workspaceDoc.error]);

  // Render the children if the user has access to the workspace
  // Render the 404 page if the workspace is loaded but the user does not have access
  // Render null if the workspace is still loading
  return workspaceDoc.error ? (
    <Error500 message="Unable to find your workspace." />
  ) : canRender ? (
    <>{children}</>
  ) : workspaceDoc.result ? (
    <Error404 />
  ) : null;
}

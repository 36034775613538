/* eslint-disable unicorn/prefer-regexp-test */
/* eslint-disable unicorn/consistent-function-scoping */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import store from 'store2';
import { MiddlewareWithThunkSupport } from 'src/@types/database/persistMiddleware';
import { ONBOARDING_STORAGE_KEY, OnboardingStore } from '../types';
import { persistedActions } from './slice';

/** Namespacing data now so it can be painlessly expanded later if needed. */
const emptyData: Pick<OnboardingStore, 'closedItems'> = { closedItems: [] };

/**
 * Listens to relevant Redux write operations, and replicates the changes
 * in the database. These changes include:
 * - hiding an onboarding item
 * - erasing persisted data.
 *
 * Hydrating the data is done by `useHydrateOnboarding()`.
 */
export const persistMiddleware: MiddlewareWithThunkSupport =
  () => (next) => (action) => {
    if (persistedActions.hideOnboardingItem.match(action)) {
      hideItem(action);
    } else if (persistedActions.resetOnboarding.match(action)) {
      eraseData();
    }
    return next(action);
  };

function hideItem(
  action: ReturnType<(typeof persistedActions)['hideOnboardingItem']>,
) {
  const storedData = readStoredData();
  storedData.closedItems.push(action.payload);
  store.set(ONBOARDING_STORAGE_KEY, storedData);
}

function eraseData() {
  store.set(ONBOARDING_STORAGE_KEY, emptyData);
}

function readStoredData() {
  const storedData = store.get(ONBOARDING_STORAGE_KEY);
  const parsedData = storedData
    ? (JSON.parse(storedData) as OnboardingStore)
    : emptyData;
  return parsedData;
}

/**
 * Reads persisted onboarding data from local storage and hydrates the Redux store.
 */
export function useHydrateOnboardingEffect() {
  const dispatch = useDispatch();
  useEffect(() => {
    const { closedItems } = readStoredData();
    dispatch(persistedActions.loadOnboardingData({ closedItems }));
  }, [dispatch]);
}

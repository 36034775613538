import { RouteObject } from 'react-router/dist/lib/context';
import { ComponentType, useMemo } from 'react';
import { matchPath, Outlet, useLocation, useMatch } from 'react-router-dom';
import { LazyComponent } from 'src/app/router/Lazy/LazyComponent';
import { buildPath, Path } from 'src/app/router/routes';
import { TypedNavigate } from 'src/app/router/TypedNavigate/TypedNavigate';

/**
 * Generates a relative path by chopping off part of an absolute path.
 * It doesn't matter if path params are interpolated or not.
 * @param fullUrl - Absolute path to be converted.
 * @param firstSegment - Path segment that should be the start of the relative URL
 * @returns Relative URL on success or `undefined`
 * @example```ts
 * const fullPath = '/:entity/path/to/subpage/1'
 * getRelativePath(fullPath, 'to') // 'to/subpage/i'
 * ```
 */
export function getRelativePath(fullUrl: string, firstSegment: string) {
  const segments = fullUrl.split('/');
  const targetIndex = segments.indexOf(firstSegment);
  const relativePath = segments.slice(targetIndex);
  return relativePath.length > 0 ? relativePath.join('/') : undefined;
}

/**
 * @returns Relative path leading to the flow instance page from the session page.
 */
export function getFlowInstancePathFromSessionPage() {
  return '..';
}

/**
 * Creates a wildcard redirect route.
 * @param to - The path to redirect to.
 * @returns The redirect component.
 */
export function createWildcardRedirect(to: Path): RouteObject {
  return {
    path: '*',
    element: <TypedNavigate to={to} replace />,
  };
}

type ImportFunc = () => Promise<{ default: ComponentType }>;

/**
 * Creates an index route.
 * @param importFunc - The function that returns the component to be rendered.
 */
export function createIndexRoute(importFunc: ImportFunc): RouteObject {
  return {
    index: true,
    element: <LazyComponent importFunc={importFunc} />,
  };
}

/**
 * Creates a lazy-loaded route.
 * @param path - Path of the route.
 * @param importFunc - The function that returns the component to be rendered.
 */
export function createLazyRoute(
  path: Path | '*',
  importFunc: () => Promise<{ default: ComponentType }>,
): RouteObject {
  return {
    path,
    element: <LazyComponent importFunc={importFunc} />,
  };
}

/**
 * Creates a base route with index, wildcard redirect and "home" element. If the children do not have an index, one will be added automatically, redirecting to the first child. If the children do not have a wildcard redirect, one will be added automatically, redirecting to the index.
 * @param path - The path of the root route
 * @param children - The children of the root route.
 */
export function createBaseRoute(
  path: Path,
  children: RouteObject[],
): RouteObject;
/**
 * Creates a base route with index, wildcard redirect and "home" element. If the children do not have an index, one will be added automatically, redirecting to the first child. If the children do not have a wildcard redirect, one will be added automatically, redirecting to the index.
 * @param path - The path of the root route
 * @param rootElementImportFunc - The function that returns the component to be rendered. Defaults to <Outlet />
 * @param children - The children of the root route.
 */
export function createBaseRoute(
  path: Path,
  rootElementImportFunc: ImportFunc,
  children?: RouteObject[],
): RouteObject;
/**
 * Creates a base route with index, wildcard redirect and "home" element.
 * If the children do not have an index, one will be added automatically,
 * redirecting to the first child.
 * If the children do not have a wildcard redirect, one will be added
 * automatically, redirecting to the index.
 * @param path - The path of the root route
 * @param childrenOrRootElementImportFunc - The children of the root route or the function that returns the component to be rendered. Defaults to <Outlet />
 * @param optionalChildren - The children of the root route.
 */
export function createBaseRoute(
  path: Path,
  childrenOrRootElementImportFunc: RouteObject[] | ImportFunc,
  optionalChildren?: RouteObject[],
): RouteObject {
  const rootElementImportFunc =
    typeof childrenOrRootElementImportFunc === 'function'
      ? childrenOrRootElementImportFunc
      : undefined;
  const children = rootElementImportFunc
    ? optionalChildren ?? []
    : (childrenOrRootElementImportFunc as RouteObject[]);

  const modifiedChildren = [...children];
  if (modifiedChildren.length === 0) {
    throw new Error('Root route must have at least one child');
  }

  if (!modifiedChildren.some((child) => child.index)) {
    modifiedChildren.unshift({
      index: true,
      element: <TypedNavigate to={modifiedChildren[0]!.path as Path} replace />,
    });
  }

  if (!modifiedChildren.some((child) => child.path === '*')) {
    modifiedChildren.push(createWildcardRedirect(path));
  }

  return {
    path,
    element: rootElementImportFunc ? (
      <LazyComponent importFunc={rootElementImportFunc} />
    ) : (
      <Outlet />
    ),
    children: modifiedChildren,
  };
}

/**
 * This hook is used to check if a route matches a valid root route.
 * @param target - Route to check.
 * @returns Path data if the tested route matches the target route.
 */
export function useBuiltPathMatch(target: Path) {
  const fullPath = buildPath(target);
  return useMatch(fullPath);
}

/**
 * This is a custom version of the `useMatch` function that allows base root
 * checking.
 * @param target - Route to check.
 * @returns Path data if the tested route matches the target route.
 */
export function useMatchBase(target: Path) {
  const location = useLocation();
  return useMemo(
    () =>
      matchPath(
        {
          path: target,
          caseSensitive: false,
          end: false,
        },
        location.pathname,
      ),
    [location, target],
  );
}

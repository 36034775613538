import { createContext } from 'react';
import {
  FocusManagerLock,
  FocusManagerLockCallback,
  FocusManagerUnlockCallback,
  IFocusManagerStateAdapter,
} from '@features/canvas/services/FocusManager/FocusManager.types';

/**
 * Context state for managing Focus Manager locks.
 * @property namespace - Namespace for the locks, defaults to 'canvas'.
 * @property locks - Array of active locks. Undefined if the adapter isn't ready.
 * @property lock - Callback for locking a key.
 * @property unlock - Callback for unlocking a key.
 */
export interface IFocusManagerContext {
  cleanup: IFocusManagerStateAdapter['cleanup'];
  namespace: string;
  locks: FocusManagerLock[] | undefined;
  lock: FocusManagerLockCallback;
  unlock: FocusManagerUnlockCallback;
}

const defaultFocusManagerContext: IFocusManagerContext = {
  cleanup: async () => {},
  namespace: 'canvas',
  locks: undefined,
  lock: () => {},
  unlock: () => {},
};

/**
 * Context provider for managing Focus Manager locks.
 */
export const FocusManagerContext = createContext<IFocusManagerContext>(
  defaultFocusManagerContext,
);

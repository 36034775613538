/**
 * Deeply clones an object while removing any custom prototypes,
 * converting all nested objects to plain objects without prototypes.
 *
 * @template T - The type of the object to be cloned.
 * @param {T} object - The object to be deeply cloned. The object can be an array, a plain object, or an object with nested objects.
 * @returns {T} A deeply cloned object where all nested objects are plain objects without prototypes.
 *
 * @example
 * // Cloning a nested object with no prototype
 * const obj = { a: 1, b: { c: 2 } };
 * const clonedObj = cloneObject(obj);
 * console.log(clonedObj); // { a: 1, b: { c: 2 } }
 * console.log(Object.getPrototypeOf(clonedObj.b)); // null
 *
 * @example
 * // Cloning an instance of a custom class
 * class MyDTO {
 *   constructor(public name: string, public value: number) {}
 * }
 * const dto = new MyDTO('test', 42);
 * const clonedDto = cloneObject(dto);
 * console.log(clonedDto); // { name: 'test', value: 42 }
 * console.log(clonedDto instanceof MyDTO); // false
 * console.log(Object.getPrototypeOf(clonedDto)); // null
 */
export function cloneObject<T extends object>(object: T): T {
  // If the value is not an object or is null, return it as is (base case).
  if (object === null || typeof object !== 'object') {
    return object;
  }

  // Handle Array separately since we want to create a new array and clone each element.
  if (Array.isArray(object)) {
    return object.map((item) => cloneObject(item)) as unknown as T;
  }

  // Create a plain object without a prototype.
  const clonedObj = Object.create(null) as T;

  // Recursively clone each property of the object.
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key];
      // Check if the property is an object before cloning it.
      clonedObj[key] =
        value !== null && typeof value === 'object'
          ? cloneObject(value)
          : value;
    }
  }

  return clonedObj;
}

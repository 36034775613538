import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../useTypedSelector';

const initialState: {
  /**
   * ID of the sidebar menu that should be active.
   * This should be the parent menu of the active content.
   * No nesting is supported.
   */
  menuId: string;
  /**
   * ID of the modal-popover, submenu, or anything that should be active.
   */
  contentId: string;
  /**
   * If `true`, neither the content, nor its parent menu is allowed to be closed.
   */
  isContentLocked: boolean;
} = {
  contentId: '',
  isContentLocked: false,
  menuId: '',
};

const slice = createSlice({
  name: 'workspaceSidebar',
  initialState,
  reducers: {
    setMenu(state, action: PayloadAction<string | false>) {
      const nextMenuId = action.payload;
      const isMenuSwitchProhibited = Boolean(
        state.menuId && state.isContentLocked,
      );
      const isMenuClosed =
        nextMenuId === initialState.menuId || nextMenuId === false;
      const isMenuOpen =
        typeof nextMenuId === 'string' && nextMenuId.length > 0;
      if (isMenuSwitchProhibited) {
        return;
      } else if (isMenuClosed) {
        state.menuId = initialState.menuId;
        state.contentId = initialState.contentId;
      } else if (isMenuOpen) {
        state.menuId = nextMenuId;
      }
    },

    setContent(state, action: PayloadAction<string | false>) {
      const isMenuSwitchProhibited = state.contentId && state.isContentLocked;
      if (isMenuSwitchProhibited) {
        return;
      } else if (typeof action.payload === 'string') {
        state.contentId = action.payload;
      } else if (action.payload === false) {
        state.contentId = '';
      }
    },

    setLock(state, action: PayloadAction<boolean>) {
      state.isContentLocked = action.payload;
    },
  },
});

export const reducer = slice.reducer;

const { setContent, setLock, setMenu } = slice.actions;

export function useSidebarState() {
  return useTypedSelector((state) => state.sidebar);
}

/**
 * Handles opening and closing a sidebar menu through Redux.
 *
 * All sidebar menu popover state should be hooked through Redux
 * (instead of relying on internal state)
 * to ensure that only one menu can be open at a time.
 *
 * @param menuId - Should be a unique ID within the sidebar's scope.
 * @returns Tuple of the menu's toggle state and its setter, similarly to the `useState` hook.
 */
export function useSidebarMenuState(menuId: string) {
  const isOpen = useSidebarState().menuId === menuId;
  const dispatch = useDispatch();
  const setIsOpen = useCallback(
    function setIsOpen(willBeOpen: boolean) {
      dispatch(setMenu(willBeOpen ? menuId : false));
    },
    [dispatch, menuId],
  );
  return useMemo(() => [isOpen, setIsOpen] as const, [isOpen, setIsOpen]);
}

/**
 * Handles opening and closing some sidebar menu content through Redux.
 *
 * All sidebar menu popover state should be hooked through Redux
 * (instead of relying on internal state)
 * to ensure that only one menu can be open at a time.
 *
 * @param contentId - Should be a unique ID within the sidebar's scope.
 * @returns Tuple of the content's toggle state and its setter, similarly to the `useState` hook.
 */
export function useSidebarMenuContentState(contentId: string) {
  const isOpen = useSidebarState().contentId === contentId;
  const dispatch = useDispatch();
  const setIsOpen = useCallback(
    function setIsOpen(willBeOpen: boolean) {
      dispatch(setContent(willBeOpen ? contentId : false));
    },
    [dispatch, contentId],
  );
  return useMemo(() => [isOpen, setIsOpen] as const, [isOpen, setIsOpen]);
}

/**
 * Handles locking and unlocking some sidebar menu content through Redux.
 *
 * If content is locked, then neither the content, nor its parent sidebar menu can be closed.
 * This prevents accidentally closing e.g. wizard forms with unsaved changes.
 *
 * All sidebar menu popover state should be hooked through Redux
 * to ensure that components are in fact locked.
 *
 * @returns Tuple of the content's lock state and its setter, similarly to the `useState` hook.
 */
export function useSidebarContentLockState() {
  const isLocked = useSidebarState().isContentLocked;
  const dispatch = useDispatch();
  const setIsLocked = useCallback(
    function setIsLocked(willBeLocked: boolean) {
      dispatch(setLock(willBeLocked));
    },
    [dispatch],
  );
  return useMemo(
    () => [isLocked, setIsLocked] as const,
    [isLocked, setIsLocked],
  );
}

/**
 * Displays a native confirm dialog to the user if the sidebar is locked.
 * @param onClose - Callback that knows if only the content should be closed, or the parent sidebar menu too.
 * @returns Memoized callback
 */
export function useConfirmCloseSidebar(onClose: () => void) {
  const [isLocked, setIsLocked] = useSidebarContentLockState();
  return useCallback(
    function closeWithConfirm() {
      if (isLocked) {
        if (
          confirm('You have unsaved changes that will be lost if you leave.')
        ) {
          setIsLocked(false);
          onClose();
        }
      } else if (!isLocked) {
        onClose();
      }
    },
    [isLocked, onClose, setIsLocked],
  );
}

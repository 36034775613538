import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import usePreviousValue from 'beautiful-react-hooks/usePreviousValue';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { SessionFooterLayout } from 'src/@types/session';
import { flushSessionData } from '../../commonActions';
import { useTypedSelector } from '../../useTypedSelector';

type FooterState = {
  isVisible: boolean;
  view: SessionFooterLayout;
};

const initialState: FooterState = {
  isVisible: true,
  view: 'expanded',
};

const slice = createSlice({
  name: 'footerLayout',
  initialState,
  reducers: {
    setLayout(_state, action: PayloadAction<FooterState>) {
      return action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(flushSessionData, () => initialState),
});

const { actions, reducer } = slice;

interface IFooterStateApi {
  /** Returns the ID of the current footer layout. */
  current: () => SessionFooterLayout;
  /** Returns `true` if the compact footer layout is active, regardless of whether it's hidden.  */
  isCompact: () => boolean;
  /** Returns `true` if the expanded footer layout is active, regardless of whether it's hidden.  */
  isExpanded: () => boolean;
  /** Returns `true` if the footer is hidden. */
  isHidden: () => boolean;
  /**
   * Returns `true` if the large or small mobile footer is active.
   */
  isMobile: () => boolean;
  /**
   * Returns the ID of the previously active footer layout.
   * Can be used to compare with current ID to see if a transition needs to be triggered.
   */
  previous: () => SessionFooterLayout;
  /**
   * Actives the layout received in the argument,
   * or toggles between the expanded and compact footer layouts.
   * */
  switchLayout: (layout?: SessionFooterLayout) => void;
  /** Shows or hides the footer in its current layout. */
  toggleHide: () => void;
}

/**
 * Allows getting current, and setting the next footer layout in Redux.
 * @returns An API to query and modify the footer layout.
 */
function useFooterLayout() {
  const view = useTypedSelector(
    (state) => state.activeSession.layout.footer.view,
  );
  const isVisible = useTypedSelector(
    (state) => state.activeSession.layout.footer.isVisible,
  );
  const previousView = usePreviousValue(view);
  const dispatch = useDispatch();
  return useMemo<IFooterStateApi>(
    () => ({
      current: () => view,
      previous: () => previousView ?? initialState.view,
      isCompact: () => view === 'compact',
      isExpanded: () => view === 'expanded',
      isHidden: () => !isVisible,
      isMobile: () => view === 'mobileLarge' || view === 'mobileSmall',
      switchLayout: (layout) => {
        let nextView: SessionFooterLayout;
        if (typeof layout === 'string') nextView = layout;
        else if (view === 'compact') {
          nextView = 'expanded';
        } else if (view === 'expanded') {
          nextView = 'compact';
        } else throw new Error(`Unknown footer layout ID: ${view}`);
        dispatch(actions.setLayout({ view: nextView, isVisible }));
      },
      toggleHide: () => {
        dispatch(actions.setLayout({ view, isVisible: !isVisible }));
      },
    }),
    [dispatch, isVisible, previousView, view],
  );
}

const footerLayoutActions = actions;

export { footerLayoutActions, reducer, useFooterLayout };

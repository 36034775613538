import isEqual from 'lodash.isequal';
import { useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Offset, Size } from 'src/@types/dnd';
import {
  createMeasurerSlice,
  useElementMeasurement,
  useGetMeasuredElementOffset,
  useGetMeasuredElementSize,
} from './_measurerFactory';

const { actions, reducer } = createMeasurerSlice('canvasRect');

/**
 * Measures a DOM element's width, height and offset, and publishes
 * these values to Redux. This information should be used for
 * correctly positioning canvas items, and their drag previews.
 *
 * The canvas represents the useful area available for creating and
 * positioning items.
 *
 * @param debounce - Interval between measurements in milliseconds
 * @returns Ref that should be attached to the measured DOM element
 */
function usePublishCanvasRect() {
  const [measureRef, rect] = useElementMeasurement();
  const dispatch = useDispatch();
  // Prevent spamming actions
  const prevRect = useRef<typeof rect | undefined>();
  useLayoutEffect(
    function publish() {
      if (!isEqual(prevRect.current, rect)) {
        prevRect.current = rect;
        dispatch(actions.set(rect));
      }
    },
    [dispatch, rect],
  );
  return measureRef;
}

/**
 * Provides the canonical width and height of the canvas area.
 * @returns The canvas size
 */
function useGetCanvasSize(): Size {
  return useGetMeasuredElementSize((state) => state.activeCanvas.canvasRect);
}

/**
 * Provides the canonical offset values of the canvas area.
 * @returns The canvas offset
 */
function useGetCanvasOffset(): Offset {
  return useGetMeasuredElementOffset((state) => state.activeCanvas.canvasRect);
}

export { reducer, usePublishCanvasRect, useGetCanvasOffset, useGetCanvasSize };

import { useSessionDefinition } from 'src/services/database/FlowDefinitions/CanvasSessions';
import { useLocalized } from 'src/utils/i18n';
import { useFlowDefinitionId, useSessionId } from 'src/utils/resource.hooks';

/**
 * Returns the session definition for the current session.
 * This hook should only be used if both the flow definition ID and session definition ID
 * are present in the URL as path params.
 */
export function useCurrentSessionDefinition() {
  return useSessionDefinition(useFlowDefinitionId(), useSessionId());
}

/**
 * Returns the title of the current session.
 * This hook should only be used if both the flow definition ID and session definition ID
 * are present in the URL as path params.
 */
export function useCurrentSessionTitle() {
  const session = useCurrentSessionDefinition();
  return useLocalized(session?.title, '');
}

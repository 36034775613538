import {
  createElement,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  PropsWithChildren,
} from 'react';

interface IFlexProps extends HTMLProps<HTMLElement> {
  /** Controls what kind of HTML tag is rendered. Defaults to `div`. */
  as?: keyof JSX.IntrinsicElements;
  /** Controls the vertical alignment of the flex children. Defaults to `center`. */
  align?: CSSProperties['alignItems'];
  /** Controls the alignment in the block direction for the selected item inside a flexbox or grid container.*/
  alignSelf?: CSSProperties['alignSelf'];
  /** Controls flow direction of the flex children. Defaults to `row`. */
  direction?: CSSProperties['flexDirection'];
  /** Controls the spacing between the flex children. */
  gap?: CSSProperties['gap'];
  /** Controls whether the flex parent is inline. */
  isInline?: boolean;
  /** Controls the horizontal alignment of the flex children. Defaults to `center`. */
  justify?: CSSProperties['justifyContent'];
  style?: CSSProperties;
  /** Controls whether overflowing flex children would wrap to a new row. */
  wrap?: CSSProperties['flexWrap'];
}

/**
 * Renders a flexbox container.
 */
export const Flex = forwardRef(function Flex(
  {
    as: tagName = 'div',
    children,
    direction = 'row',
    justify = 'center',
    align = 'center',
    alignSelf = 'auto',
    gap,
    isInline,
    style,
    wrap,
    ...restOfProps
  }: PropsWithChildren<IFlexProps>,
  ref: ForwardedRef<HTMLElement>,
) {
  return createElement(
    tagName,
    {
      ...restOfProps,
      ref,
      style: {
        ...style,
        display: isInline ? 'inline-flex' : 'flex',
        flexDirection: direction,
        alignItems: align,
        alignSelf,
        justifyContent: justify,
        flexWrap: wrap,
        gap,
      },
    },
    children,
  );
});
